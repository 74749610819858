<template>
  <v-row class="my-0">
    <v-col cols=12>
      <v-toolbar flat tile color="transparent">
        <v-spacer/>
        <span
          v-if="users.length"
          class="caption my-auto">
          {{ $t('showing') }} <span style="color:#1E88E5">{{ users.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
        </span>
        <v-divider
          v-if="pagination?.has_next_page"
          class="mx-4"
          vertical/>
        <v-btn
          v-if="pagination?.has_next_page"
          color="blue darken-1"
          outlined
          :loading="loadingMore"
          @click="$emit('loadNextUserPage')">
          <v-icon left>mdi-download</v-icon>
          {{ $t('common.buttons.loadMore') }}
        </v-btn>
      </v-toolbar>
    </v-col>
    <template v-if="users.length && !loading">
      <v-col
        v-for="user in users"
        :key="user.id"
        sm=6 md=4 lg=3>
        <v-card
          tile
          elevation="3"
          class="user-card"
          :ripple="false"
          @click="onUserClick(user)">
          <v-list-item>
            <v-list-item-avatar tile size="160">
              <v-img
                lazy-src="@/assets/images/no-user-picture.jpeg"
                :src="user.picture ? getPictureURL(user.picture) : require('@/assets/images/no-user-picture.jpeg')"
                alt="User Picture"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card flat tile>
                <v-card-title class="mb-3">
                  <span class="subtitle-1">
                    {{ user.full_name | truncate(25, '...') }}
                  </span>
                  <v-spacer/>
                  <v-menu
                    v-if="
                      (
                        hasPermission('change_user')
                        || hasPermission('delete_user')
                      )
                      && ![1, 2, userMe.id].includes(user.id)
                    "
                    close-on-click
                    close-on-content-click
                    offsetY
                    left>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" tile icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="hasPermission('change_user')"
                        @click="user.is_active ? $emit('deactivateUser', user) : $emit('activateUser', user)">
                        <span v-if="user.is_active">{{ $t('common.buttons.deactivate') }}</span>
                        <span v-else>{{ $t('common.buttons.activate') }}</span>
                      </v-list-item>
                      <v-list-item
                        v-if="hasPermission('delete_user')"
                        @click="$emit('deleteUser', user)">
                        <v-icon small class="mr-4">mdi-delete</v-icon>
                        {{ $t('common.buttons.delete') }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-subtitle class="mb-1">
                  <v-chip
                    x-small
                    outlined
                    label
                    class="font-weight-bold"
                    :color="user.is_active ? 'green' : 'red'">
                    {{ user.is_active ? $t('common.labels.activated') : $t('common.labels.deactivated') }}
                  </v-chip>
                  <v-chip
                    x-small
                    label
                    :color="user.user_type === 'system' ? 'green' : 'primary'"
                    class="font-weight-bold white--text ml-2">
                    {{ user.user_type_display }}
                  </v-chip>
                </v-card-subtitle>
                <v-card-subtitle v-if="![1, 2].includes(user.id)" class="caption">
                  <v-icon x-small left>mdi-email</v-icon>
                  {{ user.email }}
                </v-card-subtitle>
                <v-card-subtitle v-if="![1, 2].includes(user.id)" class="caption">
                  <v-icon x-small left>mdi-account-voice</v-icon>
                  {{ getFlag(user.language) }}
                </v-card-subtitle>
                <v-card-subtitle v-if="![1, 2].includes(user.id)" class="caption">
                  <v-icon x-small left>mdi-account-plus</v-icon>
                  {{ user.date_joined | moment("calendar") }}
                </v-card-subtitle>
                <v-card-subtitle class="caption">
                  <v-icon x-small left>mdi-account-clock</v-icon>
                  <template v-if="user.last_login"> {{ user.last_login | moment("calendar") }}</template>
                  <template v-else> n/a</template>
                </v-card-subtitle>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!users.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="UserFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'UserKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
  methods: {
    onUserClick (user) {
      this.$router.push({
        name: 'UserFormRead',
        params: { userId: user.id },
      })
    },
    getFlag (language) {
      const flags = {
        en: '🇬🇧',
        fr: '🇫🇷',
      }
      return flags[language] || 'no-flag-found'
    },
  },
}
</script>

<style scoped>
  .user-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
  .user-card .v-avatar {
    margin: 0 24px 0 0;
    border-right: 2px solid rgba(0, 0, 0, 0.05);
  }
  .user-card .v-list-item__content{
    height: 160px;
    padding: 0;
    transition: all 0.15s;
    cursor: pointer;
  }
  .user-card .v-list-item {
    padding: 0;
  }
  .user-card .v-card {
    height: 100%;
  }
  .user-card .v-card__title {
    height: 36px;
  }
  .user-card .v-card__title,
  .user-card .v-card__subtitle,
  .user-card .v-card__text {
    padding: 0;
  }
</style>
