<template>
  <helpdesk-ticket-category-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import HelpdeskTicketCategoryForm from './HelpdeskTicketCategoryForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'HelpdeskTicketCategoryFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { HelpdeskTicketCategoryForm },
  data () {
    return {
      formIsEditing: true,
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
