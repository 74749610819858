<template>
  <div class="breadcrumb">
    <a
      v-for="stage in ticketStages"
      :key="stage.id"
      :class="{
        'active': stage.id === ticket.stage.id,
        'white--text': stage.id === ticket.stage.id,
        'disable-events': !hasPermission('change_helpdeskticket'),
        'is-closing': stage.is_closing,
      }"
      @click="$emit('changeTicketStage', stage)">
      <div class="stage-content">
        <span class="stage-name">
          {{ stage.name }}
        </span>
        <span v-if="!stage.is_closing && hasStageTimingInfo(stage)" class="stage-time caption">
          {{ formatTime(ticket.time_per_stage[stage.id]) }}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HelpdeskTicketStatusesBar',
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    ticketStages: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    hasStageTimingInfo (stage) {
      const stageData = this.ticket.time_per_stage && this.ticket.time_per_stage[stage.id]
      return !!stageData
    },
    formatTime (seconds) {
      const years = Math.floor(seconds / (3600 * 24 * 365))
      const months = Math.floor(seconds / (3600 * 24 * 30))
      const weeks = Math.floor(seconds / (3600 * 24 * 7))
      const days = Math.floor(seconds / (3600 * 24))
      const hours = Math.floor((seconds % (3600 * 24)) / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = Math.floor(seconds % 60)
      // Display the largest non-zero unit with translation
      if (years > 0) {
        return `${years} ${this.$tc('timing.years', years)}`
      } else if (months > 0) {
        return `${months} ${this.$tc('timing.months', months)}`
      } else if (weeks > 0) {
        return `${weeks} ${this.$tc('timing.weeks', weeks)}`
      } else if (days > 0) {
        return `${days} ${this.$tc('timing.days', days)}`
      } else if (hours > 0) {
        return `${hours} ${this.$tc('timing.hours', hours)}`
      } else if (minutes > 0) {
        return `${minutes} ${this.$tc('timing.minutes', minutes)}`
      } else {
        return `${secs} ${this.$tc('timing.seconds', secs)}`
      }
    },
  },
}
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
.breadcrumb,
.breadcrumb a {
  height: 40px;
  display: inline-block;
}

.breadcrumb {
  background-color: #e5e9e9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.breadcrumb a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 10px 0 20px;
  position: relative;
  width: 100%;
}
.breadcrumb a .stage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.breadcrumb a .stage-time {
  margin-top: -5px;
}
.breadcrumb a:first-child {
  padding-left: 16px;
}
.breadcrumb a:last-child {
  padding-right: 16px;
}
.breadcrumb a:after,
.breadcrumb a:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  /* make left half the height */
  border-left: 10px solid transparent;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 2;
}
.breadcrumb a:before {
  margin-left: 1px;
  z-index: 1;
}

.breadcrumb a:last-child:after,
.breadcrumb a:last-child:before {
  border: none;
}

/* .breadcrumb {
  border-color: #9C9E9E;
} */

.breadcrumb a {
  color: #454545;
  background-color: #e5e9e9;
  font-weight: 500;
}

.breadcrumb a:after {
  border-left-color: #e5e9e9;
}
.breadcrumb a:before {
  border-left-color: #9C9E9E;
}

.breadcrumb a:hover {
  background: #BBDEFB;
}
.breadcrumb a:hover:after {
  border-left-color: #BBDEFB;
}
.breadcrumb a.is-closing:hover {
  background: #C8E6C9;
}
.breadcrumb a.is-closing:hover:after {
  border-left-color: #C8E6C9;
}

.breadcrumb a.active {
  background-color: #2196F3;
}
.breadcrumb a.active:after {
  border-left-color: #2196F3;
}
.breadcrumb a.active:before {
  margin-left: 0px;  /* To remove some little ugly space in the arrow */
  border-left-color: #2196F3;
}

.breadcrumb a.active.is-closing {
  background-color: #4CAF50;
}
.breadcrumb a.active.is-closing:after {
  border-left-color: #4CAF50;
}
.breadcrumb a.active.is-closing:before {
  border-left-color: #4CAF50;
}
</style>
