<template>
  <v-main class="reset-password-content">
    <v-card
      color="#404040"
      elevation="20"
      width="380"
      class="mx-auto my-auto">
      <v-card-title class="reset-password-card-title justify-center white--text">
        <h2>{{ $t('applications.base.resetPasswordForm.resetPassword') }}</h2>
      </v-card-title>
      <v-divider class="my-2"/>
      <v-card-text
        v-if="!requestSuccessful"
        v-on:keyup.enter="resetPassword()">
        <v-form v-model="isFormValid" @submit.prevent>
          <v-text-field
            v-model="form.password"
            solo
            dark
            clearable
            required
            maxlength="128"
            counter
            autofocus
            class="reset-password-input"
            :label="$t('common.labels.newPassword')"
            :rules="[formRules.required]"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"/>
          <v-text-field
            v-model="form.confirm"
            solo
            dark
            clearable
            required
            maxlength="128"
            counter
            class="reset-password-input"
            :label="$t('common.labels.newPasswordConfirm')"
            :error="!!errorMessage"
            :error-messages="errorMessage"
            :rules="[formRules.required, formRules.confirmIsEqual]"
            :type="showConfirm ? 'text' : 'password'"
            :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirm = !showConfirm"/>
        </v-form>
        <v-btn
          block
          rounded
          :depressed="isFormValid ? false : true"
          :color="isFormValid ? 'green' : 'grey'"
          :class="{
            'disable-events': !isFormValid || !token || loadingReset,
            'white--text': isFormValid,
          }"
          @click="resetPassword()">
          {{ $t('applications.base.resetPasswordForm.sendButton') }}
        </v-btn>
      </v-card-text>
      <v-card-text v-else style="opacity: 0.6;">
        <div class="d-flex justify-center mb-2">
          <video width="150" height="150" autoplay muted>
            <source :src="require('@/assets/videos/check_animation.webm')" type="video/webm">
          </video>
        </div>
        <div class="d-flex justify-center white--text">
          <h2>
            {{ $t('applications.base.resetPasswordForm.requestSuccess') }}
          </h2>
        </div>
      </v-card-text>
      <v-divider class="my-2"/>
      <v-btn
        tile
        block
        text
        color="grey"
        class="white--text overline"
        :to="{ name: 'LoginForm' }">
        {{ $t('applications.base.resetPasswordForm.backToLoginButton') }}
      </v-btn>
      <div class="h-poweredby-bar mt-4 justify-center align-center">
          <span class="overline white--text">Powered by</span>
          <a href="https://www.hubbiz.be" target="_blank">
            <v-img
              src="@/assets/images/hubbiz_full_mix_white_h.png"
              contain
              max-height="20"
              max-width="100"/>
          </a>
      </div>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  data () {
    return {
      loadingReset: false,
      isFormValid: false,
      showPassword: false,
      showConfirm: false,
      requestSuccessful: false,
      errorMessage: '',
      form: {
        password: '',
        confirm: '',
      },
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        confirmIsEqual: value => value === this.form.password || this.$t('common.errors.passwordsDoNotMatch'),
      },
      token: '',
    }
  },
  mounted () {
    this.token = this.$route.query.token
  },
  methods: {
    resetPassword () {
      this.loadingReset = true
      this.axios
        .post('reset-password', {
          password: this.form.password,
          token: this.token,
        })
        .then(response => {
          if (response.status === 204) {
            this.requestSuccessful = true
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data.detail
        })
        .finally(() => {
          this.loadingReset = false
        })
    },
  },
}
</script>

<style>
.v-main.reset-password-content >
  .v-main__wrap {
    display: flex;
    background-image: url('@/assets/images/hubbiz_login_wallpaper.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
    background-color: rgba(255, 255, 255, 0.1);
  }
.v-input.reset-password-input >
  .v-input__control >
    .v-input__slot {
      opacity: 0.5;
    }
.v-input.reset-password-input >
  .v-input__control >
    .v-input__slot >
      .v-text-field__slot {
        margin-left: 15px;
        margin-right: 15px;
      }

</style>
<style scoped>
.v-card__title.reset-password-card-title {
  opacity: 0.6;
  text-align: center;
  word-break: normal;
}
.disable-events {
  pointer-events: none;
}
div.h-poweredby-bar {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2)!important;
}
div.h-poweredby-bar span {
  opacity: 0.6;
}
</style>
