<template>
  <v-card tile elevation="3">
    <v-toolbar
      v-if="
        category
        && !editing
        && (
          hasPermission('add_helpdeskticketcategory')
          || hasPermission('change_helpdeskticketcategory')
          || hasPermission('delete_helpdeskticketcategory')
      )"
      flat
      dense>
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_helpdeskticketcategory')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_helpdeskticketcategory')"
          text
          @click="deleteCategory(category)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          v-if="hasPermission('add_helpdeskticketcategory')"
          text
          :to="{ name: 'HelpdeskTicketCategoryFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="category"/>
    <template v-if="!loadingCategory">
      <v-form
        v-model="isFormValid"
        class="pa-4"
        :disabled="!editing">
        <v-row class="my-0">
          <v-col cols=12 md=6>
            <v-text-field
              v-model="form.name"
              label="Name"
              required
              clearable
              autofocus
              type="text"
              maxlength="50"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols=12 lg=3 md=6>
            <v-text-field v-model="form.color" :label="$tc('common.labels.colors', 1)" maxlength="9" :counter="editing">
              <template v-slot:prepend>
                <v-menu
                  offsetY
                  left
                  :close-on-content-click="false"
                  :disabled="!editing">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" :style="colorMenuStyle"></div>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="form.color"
                        flat
                        hide-mode-switch/>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            v-if="hasPermission('change_helpdeskticketcategory') || hasPermission('add_helpdeskticketcategory')"
            text
            color="green"
            :disabled="!isFormValid"
            @click="!category ? createCategory() : updateCategory(category)">
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketCategoryService from '@/services/helpdesk/helpdesk.ticket.category.service'
export default {
  name: 'HelpdeskTicketCategoryForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    helpdeskTicketCategoryId: {
      type: [Number, String],
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingCategory: false,
      category: null,
      form: {
        name: '',
        color: '#BEBEBEFF',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    if (this.helpdeskTicketCategoryId) await this.getCategory(this.helpdeskTicketCategoryId)
    if (this.category) this.initFormFields()
  },
  computed: {
    colorMenuStyle () {
      const color = this.form.color
      return {
        backgroundColor: color,
        border: '1px solid black',
        cursor: 'pointer',
        height: '25px',
        width: '25px',
      }
    },
  },
  methods: {
    async getCategory (categoryId) {
      this.loadingCategory = true
      this.category = await HelpdeskTicketCategoryService.getCategory(categoryId)
      this.loadingCategory = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.category[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.category) {
        for (let field in this.form) {
          this.form[field] = this.category[field]
        }
      } else {
        history.back()
      }
    },
    async createCategory () {
      const { name, color } = this.$data.form
      const createdCategory = await HelpdeskTicketCategoryService.createCategory({
        name: name,
        color: color,
      })
      if (createdCategory) {
        this.cancelEdit()
        this.$router.push({ name: 'HelpdeskTicketCategoryFormRead', params: { helpdeskTicketCategoryId: createdCategory.id } })
        this.$dialog.notify.success(
          `Category '${createdCategory.name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateCategory (category) {
      const { name, color } = this.$data.form
      const updatedCategory = await HelpdeskTicketCategoryService.updateCategory(category.id, {
        name: name,
        color: color,
      })
      if (updatedCategory) {
        this.category = updatedCategory
        this.cancelEdit()
        this.$dialog.notify.success(
          'Category updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteCategory (category) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Category',
        recordNamePlural: 'Categories',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketCategoryService.deleteCategories({ ids: [category.id] })
          if (response) {
            this.$router.push({ name: 'HelpdeskTicketCategoryListing' })
            this.$dialog.notify.success(
              `Category '${category.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
