<template>
  <helpdesk-ticket-category-form
    :helpdeskTicketCategoryId=helpdeskTicketCategoryId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import HelpdeskTicketCategoryForm from './HelpdeskTicketCategoryForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'HelpdeskTicketCategoryFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { HelpdeskTicketCategoryForm },
  props: {
    helpdeskTicketCategoryId: {
      type: [Number, String],
      default: () => { return null },
    },
  },
  data () {
    return {
      formIsEditing: false,
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
