<template>
  <v-main class="helpdesk-ticket-feedback-form">
    <v-card
      color="grey lighten-3"
      elevation="20"
      width="600"
      class="mx-auto my-auto">
      <v-card-title style="background-color: #E0E0E0;" class="justify-center">
        {{ $tc('common.labels.feedbacks', 1) }}
      </v-card-title>
      <v-divider class="mb-2"/>
      <template v-if="loadingCheckToken">
        <div class="d-flex justify-center">
          <h-request-loading text=""/>
        </div>
        <div
          class="d-flex justify-center overline"
          style="margin-top: -32px; margin-bottom: 64px;">
          <h3>...{{ $t('applications.helpdesk.ticketFeedbackForm.checkingToken') }}...</h3>
        </div>
      </template>
      <template v-else-if="!tokenOK">
        <div class="d-flex justify-center mb-2">
          <video width="150" height="150" autoplay muted>
            <source :src="require('@/assets/videos/error_animation.webm')" type="video/webm">
          </video>
        </div>
        <div class="d-flex justify-center overline mb-8">
          <h3>{{ $t('applications.helpdesk.ticketFeedbackForm.invalidToken') }}</h3>
        </div>
      </template>
      <template v-else-if="feedbackOK">
        <div class="d-flex justify-center mb-2">
          <video width="150" height="150" autoplay muted>
            <source :src="require('@/assets/videos/check_animation.webm')" type="video/webm">
          </video>
        </div>
        <div class="d-flex justify-center overline mb-8">
          <h3>{{ $t('applications.helpdesk.ticketFeedbackForm.thankYou') }}</h3>
        </div>
      </template>
      <template v-else>
        <v-card-subtitle class="subtitle-2">
          {{ $t('applications.helpdesk.ticketFeedbackForm.pleaseReview') }} :
          <div class="my-2">
            <a @click="openTicketForm" style="color:#1E88E5; text-decoration: underline;">
              <v-icon small left style="color:#1E88E5">mdi-open-in-new</v-icon>
              #{{ helpdeskTicketId }} : {{ ticketName }}
            </a>
          </div>
          {{ $t('applications.helpdesk.ticketFeedbackForm.improve') }}
        </v-card-subtitle>
        <v-divider class="mx-6"/>
        <v-card-text>
          <v-form>
            <div class="d-flex">
              <v-rating
                v-model="form.score"
                hover
                large
                length="5"
                half-increments
                background-color="amber"
                color="amber darken-2"/>
                <span class="my-auto ml-4 text-h6">{{ form.score }} / 5</span>
            </div>
            <v-textarea
              v-model="form.comment"
              background-color="white"
              autofocus
              outlined
              rows="5"
              no-resize
              auto-grow
              counter
              maxlength="255"
              class="mt-3"
              :placeholder="`${$t('applications.helpdesk.ticketFeedbackForm.letUsComment')}...`"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="green"
            class="white--text"
            :disabled="form.score <= 0 || loadingFeedback"
            :loading="loadingFeedback"
            @click="submit">
            {{ $t('common.buttons.submit') }}
          </v-btn>
        </v-card-actions>
      </template>
      <div class="h-poweredby-bar mt-4 justify-center align-center">
        <span class="overline white--text">Powered by</span>
        <a href="https://www.hubbiz.be" target="_blank">
          <v-img
            src="@/assets/images/hubbiz_full_mix_white_h.png"
            contain
            max-height="20"
            max-width="100"/>
        </a>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: "HelpdeskTicketFeedbackForm",
  components: {
    HRequestLoading,
  },
  props: {
    helpdeskTicketId: {
      type: [Number, String],
      default: () => { return null }
    },
  },
  data () {
    return {
      loadingCheckToken: false,
      loadingFeedback: false,
      feedbackOK: false,
      tokenOK: false,
      token: '',
      form: {
        score: 0,
        comment: '',
      },
      ticketName: '',
      errorMessage: '',
    }
  },
  async mounted () {
    this.token = this.$route.query.token
    await this.checkFeedbackToken(this.token)
  },
  methods: {
    async checkFeedbackToken (token) {
      this.loadingCheckToken = true
      this.axios
        .post(`helpdesk/tickets/${this.helpdeskTicketId}/check-feedback-token`, {
          token: token,
        })
        .then(response => {
          if (response.status === 200) {
            this.tokenOK = true
            this.ticketName = response.data.ticket_name
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data.detail
          this.loadingCheckToken = false
        })
        .finally(() => {
          this.loadingCheckToken = false
        })
    },
    async submit () {
      this.loadingFeedback = true
      this.axios
        .post(`helpdesk/tickets/${this.helpdeskTicketId}/feedback`, {
          token: this.token,
          score: this.form.score,
          comment: this.form.comment,
        })
        .then(response => {
          if (response.status === 204) {
            this.feedbackOK = true
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data.detail
          this.loadingFeedback = false
        })
        .finally(() => {
          this.loadingFeedback = false
        })
    },
    openTicketForm () {
      const ticketURL = `${window.location.origin}/helpdesk/tickets/${this.helpdeskTicketId}`
      window.open(ticketURL, '_blank')
    },
  },
}
</script>

<style>
.v-main.helpdesk-ticket-feedback-form >
  .v-main__wrap {
    display: flex;
    background-image: url('@/assets/images/hubbiz_login_wallpaper.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
    background-color: rgba(255, 255, 255, 0.1);
  }
</style>
<style scoped>
.disable-events {
  pointer-events: none;
}
div.h-poweredby-bar {
  display: flex;
  background-color: #404040!important;
}
div.h-poweredby-bar span {
  opacity: 0.6;
}
</style>
