<template>
  <v-row dense class="h-ticket-form">
    <v-col
      cols="12"
      :lg="ticket ? 8 : 12"
      class="h-ticket-form-col-ticket">
      <v-card tile elevation="3">
        <v-toolbar
          v-if="
            ticket
            && (
              hasPermission('add_helpdeskticket')
              || hasPermission('change_helpdeskticket')
              || hasPermission('delete_helpdeskticket')
            )
          "
          flat
          class="h-ticket-form-toolbar">
          <v-toolbar-items>
            <v-btn
              v-if="ticket && !editing"
              text
              :color="ticket.is_closed ? 'green' : 'red'"
              :style="{'pointer-events': 'none'}">
              {{ ticket.is_closed ? 'Closed' : 'Open' }}
            </v-btn>
            <v-btn
              v-if="ticket && !editing"
              text
              :color="slaStatusAttributes(ticket).color"
              :style="{'pointer-events': 'none'}">
              <v-icon left :color="slaStatusAttributes(ticket).color">
                mdi-{{ slaStatusAttributes(ticket).icon }}
              </v-icon>
              {{ slaStatusAttributes(ticket).text }}
            </v-btn>
            <v-divider v-if="!editing" vertical class="mx-2"/>
            <v-menu
              v-if="$vuetify.breakpoint.lgAndDown && !editing"
              tile
              offsetY
              bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text>
                  Actions
                  <v-icon small right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list class="h-menu-list">
                <v-list-item v-if="hasPermission('add_helpdeskticket')" :to="{ name: 'HelpdeskTicketFormCreate' }">
                  <v-list-item-title>
                    <v-icon small class="mr-4">mdi-plus</v-icon>
                    Create Another
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    ticket
                    && hasPermission('change_helpdeskticket')
                    && mePartOfTeam
                    && !ticketIsAssignedToMe
                  "
                  @click="assignToMe(ticket)">
                  <v-list-item-title>
                    <v-icon small class="mr-4">mdi-account-arrow-left</v-icon>
                    Assign to Me
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="hasPermission('change_helpdeskticket')" @click="edit(ticket)">
                  <v-list-item-title>
                    <v-icon small class="mr-4">mdi-pencil</v-icon>
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="hasPermission('change_helpdeskticket')" @click="deleteTicket(ticket)">
                  <v-list-item-title>
                    <v-icon small class="mr-4">mdi-delete</v-icon>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <template v-else-if="$vuetify.breakpoint.xlOnly && !editing">
              <v-btn
                v-if="hasPermission('add_helpdeskticket')"
                text
                :to="{ name: 'HelpdeskTicketFormCreate' }">
                <v-icon small left>mdi-plus</v-icon>
                Create Another
              </v-btn>
              <v-btn
                v-if="
                  ticket
                  && hasPermission('change_helpdeskticket')
                  && mePartOfTeam
                  && !ticketIsAssignedToMe
                "
                text
                @click="assignToMe(ticket)">
                <v-icon small left>mdi-account-arrow-left</v-icon>
                Assign to Me
              </v-btn>
              <v-btn
                v-if="hasPermission('change_helpdeskticket')"
                text
                @click="edit(ticket)">
                <v-icon small left>mdi-pencil</v-icon>
                Edit
              </v-btn>
              <v-btn
                v-if="hasPermission('delete_helpdeskticket')"
                text
                @click="deleteTicket(ticket)">
                <v-icon small left>mdi-delete</v-icon>
                Delete
              </v-btn>
            </template>
            <template v-if="shouldShowTimer">
              <v-spacer/>
              <helpdesk-ticket-form-timer
                ref="ticketFormTimer"
                :disabled="ticket.is_closed"
                :shouldStartOnMounted="shouldStartTimerOnMounted"
                :ticketId="ticket.id"
                :ticketHasBeenDeleted="ticketHasBeenDeleted"/>
            </template>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider/>
        <helpdesk-ticket-statuses-bar
          v-if="!editing"
          :ticket="ticket"
          :ticketStages="breadcrumbStages"
          @changeTicketStage="changeTicketStage"/>
        <v-divider v-if="ticket"/>
        <template v-if="!loadingTicket">
          <v-form
            class="pa-4"
            v-model="isFormValid"
            :disabled="!editing">
            <v-text-field
              v-model="form.name"
              label="Title"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="editing"
              :rules="[formRules.required]"/>
            <h-rich-text-editor
              v-model="form.description"
              class="mt-4"
              required
              editorBorders
              editorPadding
              opacifyDisabled
              formatParagraph
              formatBold
              formatItalic
              formatStrike
              formatMarker
              formatH1
              formatH2
              formatH3
              formatListB
              formatListN
              formatCode
              formatCodeBlock
              formatSeparator
              historyUndo
              historyRedo
              :editable="editing"/>
            <v-row class="my-0">
              <v-col cols=12 sm=6>
                <v-autocomplete
                  v-model="form.company"
                  label="Company"
                  clearable
                  :disabled="disableCompanyFormInput"
                  :loading="loadingCompaniesInput"
                  :items="companies"
                  item-text="name"
                  return-object
                  @click="getCompaniesInput"
                  @change="changeCompanySelection()"/>
              </v-col>
              <v-col cols=12 sm=6>
                <v-autocomplete
                  v-model="form.contact"
                  label="Contact"
                  clearable
                  :loading="loadingContactsInput"
                  :items="contacts"
                  item-text="full_identification"
                  return-object
                  @click="getContactsInput(form.company)"
                  @change="changeContactSelection()"/>
              </v-col>
            </v-row>
            <v-row
              v-if="isInventoryAppActivated && !ticket"
              class="my-0">
              <v-col cols="12" class="pt-0">
                <v-expansion-panels
                  tile
                  flat
                  class="item-panel"
                  :class="{ 'disabled': !editing || !form.company }">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down" class="py-0">
                      <v-list-item :two-line="!form.company" class="pa-0">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>
                            Inventory Items ({{ form.inventory_items.length }})
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="!form.company">
                            First select a company to enable items choice
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-0 mb-2">
                        <v-spacer/>
                        <v-btn
                          outlined
                          color="blue darken-1"
                          :disabled="!editing || !form.company"
                          @click="selectInventoryItemsToLink">
                          <v-icon left>mdi-link-variant-plus</v-icon>
                          Link Items
                        </v-btn>
                      </v-row>
                      <v-data-table
                        :headers="ticketInventoryItemsTableHeaders"
                        :items="form.inventory_items"
                        :items-per-page=5
                        :hide-default-footer="form.inventory_items.length < 6"
                        :footer-props="{
                          'disable-items-per-page': true,
                          'items-per-page-options': [5],
                        }">
                        <template
                          v-if="hasPermission('change_helpdeskticket')"
                          v-slot:[`item.actions`]="{ item }">
                          <v-tooltip right open-delay="250">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="removeInventoryItemFromSelection(item)">
                                <v-icon>mdi-link-variant-remove</v-icon>
                              </v-btn>
                            </template>
                            Unlink Item
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="form.category"
                  clearable
                  return-object
                  item-text="name"
                  :items="categories"
                  :loading="loadingCategoriesInput"
                  :label="$tc('common.labels.categories', 1)"
                  @click="getCategoriesInput">
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      label
                      outlined
                      class="font-weight-bold"
                      :color="data.item.color">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-chip
                      small
                      label
                      outlined
                      class="font-weight-bold"
                      :color="data.item.color">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="form.priority"
                  label="Priority"
                  :menu-props="{ top: false, offsetY: true }"
                  :items="priorities"
                  item-value="value">
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon :color="item.iconColor">mdi-{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{ item.text }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="data">
                    <v-icon class="mr-2" :color="data.item.iconColor">mdi-{{ data.item.icon }}</v-icon>{{ data.item.text }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="form.tags"
                  label="Tags"
                  multiple
                  hide-selected
                  clearable
                  :loading="loadingTagsInput"
                  :items="tags"
                  item-text="name"
                  return-object
                  @click="getTagsInput">
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      class="font-weight-bold"
                      :color="data.item.color"
                      :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
                      :close="editing"
                      @click:close="removeTagFromSelection(data.item)">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-chip
                      small
                      class="font-weight-bold"
                      :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
                      :color="data.item.color">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-file-input
              v-if="!helpdeskTicketId"
              v-model="form.attachments"
              small-chips
              multiple
              counter
              show-size
              clearable
              placeholder="Select file(s) to attach..."
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              :hint="form.recurring ? 'Attachments will be included only in initial ticket, not in ticket(s) created by recurrence.' : ''"
              persistent-hint/>
            <v-row class="my-0">
              <v-col cols="4">
                <v-select
                  label="Team"
                  v-model="form.team"
                  :loading="loadingTeamsInput"
                  :items="teams"
                  item-text="name"
                  return-object
                  :menu-props="{ top: false, offsetY: true }"
                  :rules="[formRules.required]"
                  @click="getTeamsInput"
                  @change="changeTeamSelection()"/>
              </v-col>
              <v-col v-if="form.team" cols="4">
                <v-select
                  label="Stage"
                  v-model="form.stage"
                  :loading="loadingTeamStagesInput"
                  :items="teamStages"
                  item-text="name"
                  :menu-props="{ top: false, offsetY: true }"
                  return-object
                  :rules="[formRules.required]"
                  @click="getTeamStagesInput(form.team)">
                  <template v-slot:item="{ item }">
                    <v-icon
                      v-if="item.is_closing"
                      small
                      left
                      color="green lighten-2">
                      mdi-check-decagram
                    </v-icon>
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="data">
                    <v-icon
                      v-if="data.item.is_closing"
                      small
                      left
                      color="green lighten-2">
                      mdi-check-decagram
                    </v-icon>
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="form.team" cols="4">
                <v-autocomplete
                  label="Assigned To"
                  v-model="form.assigned_user"
                  :loading="loadingTeamMembers"
                  :items="teamMembers"
                  item-text="full_name"
                  return-object
                  clearable
                  @click="getTeamMembers(form.team)">
                  <!-- TODO: add helpdesk_assigned_tickets_count to the displayed list -->
                  <!-- <template v-slot:no-data>
                    TODO: handle no-data or prepend-item or with a prepend/append-icon create button
                    <v-btn text>This is a no-data button</v-btn>
                  </template>
                  <template v-slot:prepend-item>
                    <v-btn text>This is a prepend-item button</v-btn>
                  </template> -->
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="!helpdeskTicketId && hasPermission('add_helpdeskrecurringtickettask')" class="my-0">
              <v-col cols="12" class="py-0">
                <v-checkbox
                  v-model="form.recurring"
                  label="Recurring Ticket"/>
              </v-col>
              <template v-if="form.recurring">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.recurring_period"
                    label="Recurring Period"
                    type="text"
                    readonly
                    @click="editTicketRecurringPeriod"
                    :rules="[formRules.requiredIfRecurring]"/>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="form.recurring_description"
                    label="Recurring Ticket Description"
                    maxlength="100"
                    counter
                    :rules="[formRules.requiredIfRecurring]"/>
                </v-col>
                <v-col cols="12" class="pt-2 pb-0">
                  <v-alert
                    dense
                    text
                    outlined
                    type="info">
                    Setting a start date will only create tickets from this date. Otherwise it will create one when saving then according to the schedule.
                  </v-alert>
                </v-col>
                <v-col cols="12" md="6">
                  <h-date-picker
                    v-model="form.recurring_start_date"
                    :minDate="recurringTicketMinStartDate"
                    :maxDate="recurringTicketMaxStartDate"
                    label="Recurring Start Date"/>
                </v-col>
                <v-col cols="12" md="6">
                  <h-date-picker
                    v-model="form.recurring_expiration_date"
                    :minDate="recurringTicketMinExpirationDate"
                    label="Recurring Expiration Date"/>
                </v-col>
              </template>
            </v-row>
          </v-form>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn
                text
                @click="cancel()">
                Cancel
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid || !form.description"
                v-if="hasPermission('add_helpdeskticket') || hasPermission('change_helpdeskticket')"
                @click="!ticket ? createTicket() : updateTicket(ticket)">
                Save
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-card>
    </v-col>
    <v-col
      v-if="ticket"
      cols="12" lg="4"
      class="h-ticket-form-col-logger">
      <helpdesk-ticket-form-logger
        ref="ticketFormLogger"
        :ticket="ticket"
        @increaseTicketInventoryItemsCount="increaseTicketInventoryItemsCount"
        @decreaseTicketInventoryItemsCount="decreaseTicketInventoryItemsCount"/>
    </v-col>
  </v-row>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import HDatePicker from '@/common/components/forms/HDatePicker'
import HelpdeskTicketInventoryItemsSelectorLinkerDialog from '@/common/components/forms/HelpdeskTicketInventoryItemsSelectorLinkerDialog'
import HCronEditorModal from '@/common/components/HCronEditorModal'
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketContactChangedDialog from './HelpdeskTicketContactChangedDialog'
import HelpdeskTicketFormLogger from './HelpdeskTicketFormLogger'
import ContactCompanyService from '@/services/contact/contact.company.service'
import ContactContactService from '@/services/contact/contact.contact.service'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
import HelpdeskTicketCategoryService from '@/services/helpdesk/helpdesk.ticket.category.service'
import HelpdeskTicketTagService from '@/services/helpdesk/helpdesk.ticket.tag.service'
import HelpdeskTicketStatusesBar from '@/views/helpdesk/ticket/shared/HelpdeskTicketStatusesBar'
import HelpdeskTicketFormTimer from '@/views/helpdesk/ticket/shared/HelpdeskTicketFormTimer'
export default {
  name: 'HelpdeskTicketForm',
  components: {
    HDatePicker,
    HelpdeskTicketFormLogger,
    HelpdeskTicketFormTimer,
    HelpdeskTicketStatusesBar,
    HRequestLoading,
    HRichTextEditor,
  },
  props: {
    editing: { type: Boolean },
    helpdeskTicketId: {
      type: [Number, String],
      default: () => { return null },
    }
  },
  data () {
    return {
      loadingTicket: false,
      loadingCategoriesInput: false,
      loadingTagsInput: false,
      loadingTeamsInput: false,
      loadingTeamStagesInput: false,
      loadingTeamMembers: false,
      loadingCompaniesInput: false,
      loadingContactsInput: false,
      ticketHasBeenDeleted: false, // Used to prevent timer to call update when beforeDestroy, after ticket delete.
      ticket: null,
      teams: [],
      teamStages: [],
      breadcrumbStages: [],
      teamMembers: [],
      companies: [],
      contacts: [],
      ticketInventoryItemsTableHeaders: [
        { value: 'name', text: 'Name' },
        { value: 'serial_number', text: 'S/N' },
        { value: 'part_number', text: 'P/N' },
        { value: 'contractor.name', text: 'Contractor' },
        { value: 'actions', text: 'Actions', sortable: false },
      ],
      priorities: [
        { text: 'Low', value: 'low', icon: 'chevron-up', iconColor: 'green' },
        { text: 'Normal', value: 'normal', icon: 'chevron-double-up', iconColor: 'orange' },
        { text: 'High', value: 'high', icon: 'chevron-triple-up', iconColor: 'red' },
      ],
      tags: [],
      categories: [],
      form: {
        name: '',
        description: '',
        priority: 'normal',
        inventory_items: [],
        tags: [],
        attachments: [],
        team: null,
        stage: null,
        category: null,
        assigned_user: null,
        company: null,
        contact: null,
        recurring: false,
        recurring_description: '',
        recurring_period: '',
        recurring_start_date: null,
        recurring_expiration_date: null,
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        requiredIfRecurring: value => this.form.recurring && !!value || 'Required if setting a recurring ticket.',
      },
    }
  },
  async mounted () {
    if (this.helpdeskTicketId) {
      await this.getTicket(this.helpdeskTicketId)
      if (this.ticket) {
        this.teamMembers = await HelpdeskTeamService.getTeamMembers(this.ticket.team.id)
        this.initFormFields()
        this.breadcrumbStages = await HelpdeskTeamService.getTeamStagesInput(this.ticket.team.id)
      }
    }
  },
  beforeDestroy () {
    this.$store.dispatch('RESET_INITIAL_TICKET_FORM_TIMER')
  },
  computed: {
    isTimesheetsAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === "timesheets") !== undefined
    },
    isUseTimerEnabled () {
      return this.$store.state.base.allSettingsByApplication.helpdesk?.use_timer_on_ticket_form
    },
    isAutoStartTimerEnabled () {
      return this.$store.state.base.allSettingsByApplication.helpdesk?.auto_start_timer_on_ticket_form
    },
    isAutoStartTimerAssigneeOnlyEnabled () {
      return this.$store.state.base.allSettingsByApplication.helpdesk?.auto_start_timer_assignee_only
    },
    isTimesheetsActivatedAndTimerEnabled () {
      return this.isTimesheetsAppActivated && this.isUseTimerEnabled
    },
    shouldShowTimer () {
      if (
        this.ticket
        && this.isTimesheetsAppActivated
        && this.isUseTimerEnabled
      ) {
        return true
      }
      return false
    },
    shouldStartTimerOnMounted () {
      if (this.ticket?.is_closed === false && this.isAutoStartTimerEnabled) {
        if (this.isAutoStartTimerAssigneeOnlyEnabled) {
          const meId = this.$store.state.login.me?.id
          const assignedUserId = this.ticket?.assigned_user?.id
          return meId !== null && assignedUserId !== null && meId === assignedUserId
        }
        return true
      }
      return false
    },
    disableCompanyFormInput () {
      if (!this.editing) {
        return true
      }
      if (this.ticket) {
        if (this.ticket.inventory_items_count) {
          if (this.ticket.company) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isInventoryAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === 'inventory') !== undefined
    },
    recurringTicketMinStartDate () {
      const now = new Date()
      return now.toISOString().split('T')[0]
    },
    recurringTicketMaxStartDate () {
      if (this.form.recurring_expiration_date) {
        return this.form.recurring_expiration_date
      }
      return ""
    },
    recurringTicketMinExpirationDate () {
      if (this.form.recurring_start_date) {
        return this.form.recurring_start_date
      }
      return this.recurringTicketMinStartDate
    },
    mePartOfTeam () {
      const meId = this.$store.state.login.me.id
      return this.teamMembers.map(member => member.id).includes(meId)
    },
    ticketIsAssignedToMe () {
      const meId = this.$store.state.login.me.id
      return this.ticket?.assigned_user?.id === meId
    },
  },
  watch: {
    'ticket.participant_time' (newValue, oldValue) {
      this.$store.dispatch('UPDATE_INITIAL_TICKET_FORM_TIMER', newValue)
    },
  },
  methods: {
    async getTicket (ticketId) {
      this.loadingTicket = true
      this.ticket = await HelpdeskTicketService.getTicket(ticketId)
      if (this.ticket && this.isTimesheetsActivatedAndTimerEnabled) {
        this.$store.dispatch('UPDATE_INITIAL_TICKET_FORM_TIMER', this.ticket.participant_time)
      }
      this.loadingTicket = false
    },
    async getCategoriesInput () {
      this.loadingCategoriesInput = true
      this.categories = await HelpdeskTicketCategoryService.getCategoriesInput()
      this.loadingCategoriesInput = false
    },
    async getTagsInput () {
      this.loadingTagsInput = true
      this.tags = await HelpdeskTicketTagService.getTagsInput()
      this.loadingTagsInput = false
    },
    async getTeamsInput () {
      this.loadingTeamsInput = true
      this.teams = await HelpdeskTeamService.getTeamsInput()
      this.loadingTeamsInput = false
    },
    async getTeamStagesInput (team) {
      this.loadingTeamStagesInput = true
      this.teamStages = await HelpdeskTeamService.getTeamStagesInput(team.id)
      this.loadingTeamStagesInput = false
    },
    async selectInventoryItemsToLink () {
      this.$dialog.show(HelpdeskTicketInventoryItemsSelectorLinkerDialog, {
        mode: 'selector',
        company: this.form.company,
        initiallySelectedOrLinkedInventoryItems: this.form.inventory_items,
        persistent: true,
        waitForResult: true,
      }).then(result => {
        if (result) {
          this.form.inventory_items = result
        }
      })
    },
    changeTeamSelection () {
      this.form.stage = null
      this.form.assigned_user = null
    },
    async getCompaniesInput () {
      this.loadingCompaniesInput = true
      this.companies = await ContactCompanyService.getCompaniesInput()
      this.loadingCompaniesInput = false
    },
    changeCompanySelection () {
      this.form.contact = null
      this.form.inventory_items = []
    },
    async changeContactSelection () {
      const selectedContact = this.form.contact
      if (selectedContact?.company_id) {
        if (!(this.form.company?.id === selectedContact.company_id)) {
          const company = this.companies.find(company => { return company?.id === selectedContact.company_id })
          if (company) {
            this.form.company = company
          } else {
            await this.getCompaniesInput()
            // NOTE: Maybe we should consider not querying all the companies here  as input, but only the one needed.
            this.form.company = this.companies.find(company => { return company?.id === selectedContact.company_id })
          }
        }
      }
    },
    async getContactsInput (company) {
      this.loadingContactsInput = true
      if (company) {
        this.contacts = await ContactCompanyService.getCompanyContactsInput(company.id)
      } else {
        this.contacts = await ContactContactService.getContactsInput()
      }
      this.loadingContactsInput = false
    },
    async getTeamMembers (team) {
      this.loadingTeamMembers = true
      this.teamMembers = await HelpdeskTeamService.getTeamMembers(team.id)
      this.loadingTeamMembers = false
    },
    slaStatusAttributes (ticket) {
      if (ticket.sla_status === 'ok') return { icon: 'clock-check', color: 'green', text: 'SLA Ok' }
      if (ticket.sla_status === 'warning') return { icon: 'clock-alert', color: 'amber lighten-1', text: 'SLA Warning' }
      if (ticket.sla_status === 'alert') return { icon: 'clock-alert', color: 'orange darken-1', text: 'SLA Alert' }
      if (ticket.sla_status === 'failed') return { icon: 'clock-remove', color: 'red', text: 'SLA Failed' }
    },
    initFormFields () {
      for (let field in this.form) {
        if (field === 'team') this.teams = [this.ticket[field]]
        if (field === 'stage') this.teamStages = [this.ticket[field]]
        if (field === 'company') this.companies = [this.ticket[field]]
        if (field === 'contact') this.contacts = [this.ticket[field]]
        if (field === 'category') this.categories = [this.ticket[field]]
        if (field === 'tags') this.tags = this.ticket[field]
        if (field === 'attachments') continue
        this.form[field] = this.ticket[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.ticket) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    editTicketRecurringPeriod () {
      this.$dialog.show(HCronEditorModal, {
        persistent: true,
        waitForResult: true,
        recurring_period: this.recurring_period,
      }).then(result => {
        if (result) {
          this.form.recurring_period = result
        }
      })
    },
    async createTicket () {
      const {
        name,
        description,
        category,
        priority,
        tags,
        team,
        stage,
        assigned_user,
        company,
        contact,
        attachments,
        inventory_items,
        recurring,
        recurring_description,
        recurring_period,
        recurring_start_date,
        recurring_expiration_date,
      } = this.$data.form
      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('priority', priority)
      formData.append('tag_ids', tags ? tags.map(tag => tag.id) : [])
      formData.append('inventory_item_ids', inventory_items ? inventory_items.map(item => item.id) : [])
      formData.append('team_id', team.id)
      formData.append('stage_id', stage.id)
      formData.append('recurring', recurring)
      formData.append('recurring_period', recurring ? recurring_period : null)
      formData.append('recurring_description', recurring ? recurring_description : "")
      formData.append('recurring_start_date', recurring ? recurring_start_date : null)
      formData.append('recurring_expiration_date', recurring ? recurring_expiration_date : null)
      formData.append('client_timezone', recurring ? Intl.DateTimeFormat().resolvedOptions().timeZone : null)
      if (category) formData.append('category_id', category.id)
      if (company) formData.append('company_id', company.id)
      if (contact) formData.append('contact_id', contact.id)
      if (assigned_user) formData.append('assigned_user_id', assigned_user.id)
      for (const attachment of attachments) {
        formData.append('attachments', attachment)
      }
      const responseData = await HelpdeskTicketService.createTicket(formData)
      if (responseData) {
        if (responseData.ticket) {
          this.cancelEdit()
          this.$dialog.notify.success(
            'Ticket created', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
          if (this.form.recurring) {
            this.$dialog.notify.success(
              'Recurring task created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
          this.$router.push({
            name: 'HelpdeskTicketFormRead',
            params: { helpdeskTicketId: responseData.ticket.id },
          })
        } else if (responseData.ticket === null) {
          // We just created the recurring task.
          this.cancelEdit()
          if (this.form.recurring) {
            // Normally if we get here, it should be always true but
            // let's be cautious.
            this.$dialog.notify.success(
              'Recurring task created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            if (this.hasPermission('view_helpdeskrecurringtickettask')) {
              this.$router.push({ name: 'HelpdeskRecurringTicketTaskList' })
            }
            // If user doesn't have permission to see list of recurring task, just go
            // back to previous view.
            history.back()
          }
        }
      }
    },
    startOrPauseTicketFormTimer (initialTicket, updatedTicket) {
      const meId = this.$store.state.login.me.id
      const closedTicket = !initialTicket.stage.is_closing && updatedTicket.stage.is_closing
      const openedTicket = initialTicket.stage.is_closing && !updatedTicket.stage.is_closing
      const changedAssignee = initialTicket.assigned_user?.id !== updatedTicket.assigned_user?.id
      const isAssignedToMe = updatedTicket.assigned_user?.id === meId
      if (
        closedTicket
        || (changedAssignee && this.isAutoStartTimerEnabled && this.isAutoStartTimerAssigneeOnlyEnabled && !isAssignedToMe)
      ) {
        this.$refs.ticketFormTimer.pauseTimer()
      } else if (
        (openedTicket && this.isAutoStartTimerEnabled && !this.isAutoStartTimerAssigneeOnlyEnabled)
        || (openedTicket && this.isAutoStartTimerEnabled && this.isAutoStartTimerAssigneeOnlyEnabled && isAssignedToMe)
        || (!updatedTicket.is_closed && changedAssignee && this.isAutoStartTimerEnabled && !this.isAutoStartTimerAssigneeOnlyEnabled)
        || (!updatedTicket.is_closed && changedAssignee && this.isAutoStartTimerEnabled && this.isAutoStartTimerAssigneeOnlyEnabled && isAssignedToMe)
      ) {
        this.$refs.ticketFormTimer.startTimer()
      }
    },
    async updateTicket (ticket) {
      const {
        name,
        description,
        category,
        priority,
        tags,
        team,
        stage,
        assigned_user,
        company,
        contact,
      } = this.$data.form
      // TODO: warning force team and stage !=== null
      let newContactExtraContextMailContent = null
      if (contact && ticket.contact?.id !== contact.id) {
        await this.$dialog.show(HelpdeskTicketContactChangedDialog, {
          previousContact: ticket.contact,
          newContact: contact,
          persistent: true,
          waitForResult: true,
        }).then(result => {
          // TODO: OMETHING WEIRD HERE WHEN SAY NO ?
          if (result.sendExtraContext) {
            newContactExtraContextMailContent = result.extraContext
          }
        }).catch(error => {
          // TODO: Do something smarter than just printing the error to console, which shouldn't be done
          console.log(error)
        })
      }
      if (this.isTimesheetsActivatedAndTimerEnabled) {
        await this.$refs.ticketFormTimer.updateTicketTimer()
      }
      const responseData = await HelpdeskTicketService.updateTicket(ticket.id, {
        name: name,
        description: description,
        category_id: category ? category.id : null,
        priority: priority,
        tag_ids: tags ? tags.map(tag => tag.id) : [],
        team_id: team.id,
        stage_id: stage.id,
        assigned_user_id: assigned_user ? assigned_user.id : null,
        company_id: company ? company.id : null,
        contact_id: contact ? contact.id : null,
        new_contact_extra_context_mail_content: newContactExtraContextMailContent,
      })
      if (responseData) {
        this.cancelEdit()
        this.ticket = responseData.ticket
        if (this.isTimesheetsActivatedAndTimerEnabled) {
          this.startOrPauseTicketFormTimer(ticket, this.ticket)
        }
        if (this.isTimesheetsAppActivated && !ticket.stage.is_closing && this.ticket.stage.is_closing) {
          // An automatic timesheet is likely to be created in backend.
          // Therefore, request them again to refresh the list of timesheets.
          await this.$refs.ticketFormLogger.getTicketTimesheets(ticket.id)
        }
        if (ticket.team.id !== responseData.ticket.team.id) {
          this.breadcrumbStages = this.teamStages
        }
        if (responseData.ticket.company && !this.form.company) {
          this.form.company = responseData.ticket.company
        }
        if (responseData.log) {
          this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
        }
        this.$dialog.notify.success(
          'Ticket updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async assignToMe (ticket) {
      const meId = this.$store.state.login.me.id
      if (this.isTimesheetsActivatedAndTimerEnabled) {
        await this.$refs.ticketFormTimer.updateTicketTimer()
      }
      const responseData = await HelpdeskTicketService.updateTicket(ticket.id, {
        assigned_user_id: meId
      })
      if (responseData) {
        this.ticket = responseData.ticket
        this.form.assigned_user = responseData.ticket.assigned_user
        if (this.isTimesheetsActivatedAndTimerEnabled) {
          this.startOrPauseTicketFormTimer(ticket, this.ticket)
        }
        if (responseData.log) {
          this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
        }
        this.$dialog.notify.success(
          'Successfully assigned you to this ticket', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async changeTicketStage (stage) {
      if (this.ticket.stage.id !== stage.id) {
        if (this.isTimesheetsActivatedAndTimerEnabled) {
          await this.$refs.ticketFormTimer.updateTicketTimer()
        }
        const responseData = await HelpdeskTicketService.updateTicket(this.ticket.id, {
          stage_id: stage.id
        })
        if (responseData) {
          const initialTicket = this.ticket
          const initialTicketStage = this.ticket.stage
          this.ticket = responseData.ticket
          if (this.isTimesheetsActivatedAndTimerEnabled) {
            this.startOrPauseTicketFormTimer(initialTicket, this.ticket)
          }
          if (this.isTimesheetsAppActivated && !initialTicketStage.is_closing && stage.is_closing) {
            // An automatic timesheet is likely to be created in backend.
            // Therefore, request them again to refresh the list of timesheets.
            await this.$refs.ticketFormLogger.getTicketTimesheets(this.ticket.id)
          }
          if (!(this.teamStages.some(s => s.id === responseData.ticket.stage.id))) {
            this.teamStages.push(responseData.ticket.stage)
          }
          this.form.stage = responseData.ticket.stage
          if (responseData.log) {
            this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
          }
          this.$dialog.notify.success(
            `Ticket moved from '${initialTicketStage.name}' to '${stage.name}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    deleteTicket (ticket) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Ticket',
        recordNamePlural: 'Tickets',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketService.deleteTickets({ ids: [ticket.id] })
          if (response) {
            this.ticketHasBeenDeleted = true
            await this.$nextTick()
            this.$dialog.notify.success(
              'Ticket deleted', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'HelpdeskTicketList' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    removeTagFromSelection (tag) {
      this.form.tags = this.form.tags.filter(item => item.id !== tag.id)
    },
    removeInventoryItemFromSelection (inventoryItem) {
      this.form.inventory_items = this.form.inventory_items.filter(item => item.id !== inventoryItem.id)
    },
    increaseTicketInventoryItemsCount (linkedInventoryItemsCount) {
      this.ticket.inventory_items_count += linkedInventoryItemsCount
    },
    decreaseTicketInventoryItemsCount (unlinkedInventoryItemsCount) {
      this.ticket.inventory_items_count -= unlinkedInventoryItemsCount
    }
  },
}
</script>

<style scoped>
  .item-panel {
    border: 1px solid rgba(0, 0, 0, 0.42);
  }
  .item-panel.disabled {
    opacity: 0.38;
  }
  .v-data-table::v-deep .v-data-footer {
    justify-content: end;
    padding-top: 8px;
  }
  .h-ticket-form-toolbar .v-toolbar__items {
    width: 100%;
  }
  .v-list.h-menu-list {
    padding: 0;
  }
  /* @media only screen and (min-width: 1264px) {
    .h-ticket-form {
      margin: 0;
      height: 100%;
    }
    .h-ticket-form-col-ticket {
      padding: 0 4px 0 0;
    }
    .h-ticket-form-col-logger {
      padding: 0 0 0 4px;
    }
  } */
</style>
