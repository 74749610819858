<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        Create Ticket in {{ team.name }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Title"
        required
        clearable
        autofocus
        type="text"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
      <h-rich-text-editor
        v-model="form.description"
        class="mt-4"
        required
        editable
        editorBorders
        editorPadding
        opacifyDisabled
        formatParagraph
        formatBold
        formatItalic
        formatStrike
        formatMarker
        formatH1
        formatH2
        formatH3
        formatListB
        formatListN
        historyUndo
        historyRedo/>
      <v-file-input
        v-model="form.attachments"
        small-chips
        multiple
        counter
        show-size
        clearable
        placeholder="Select file(s) to attach..."
        prepend-icon=""
        prepend-inner-icon="mdi-paperclip"/>
      <v-row class="my-0">
        <v-col cols=12 sm=6>
          <v-autocomplete
            v-model="form.company"
            label="Company"
            clearable
            :loading="loadingCompaniesInput"
            :items="companies"
            item-text="name"
            return-object
            @click="getCompaniesInput"
            @change="changeCompanySelection()"/>
        </v-col>
        <v-col cols=12 sm=6>
          <v-autocomplete
            v-model="form.contact"
            label="Contact"
            clearable
            :loading="loadingContactsInput"
            :items="contacts"
            item-text="full_identification"
            return-object
            @click="getContactsInput(form.company)"
            @change="changeContactSelection()"/>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols=12 sm=6>
          <v-autocomplete
            v-model="form.category"
            clearable
            return-object
            item-text="name"
            :items="categories"
            :loading="loadingCategoriesInput"
            :label="$tc('common.labels.categories', 1)"
            @click="getCategoriesInput">
            <template v-slot:selection="data">
              <v-chip
                small
                label
                outlined
                class="font-weight-bold"
                :color="data.item.color">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-chip
                small
                label
                outlined
                class="font-weight-bold"
                :color="data.item.color">
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols=12 sm=6>
          <v-select
            v-model="form.priority"
            label="Priority"
            :menu-props="{ top: false, offsetY: true }"
            :items="priorities"
            item-value="value">
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                  <v-icon :color="item.iconColor">mdi-{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ item.text }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="data">
              <v-icon class="mr-2" :color="data.item.iconColor">mdi-{{ data.item.icon }}</v-icon>{{ data.item.text }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-autocomplete
        v-model="form.tags"
        label="Tags"
        multiple
        hide-selected
        clearable
        :loading="loadingTagsInput"
        :items="tags"
        item-text="name"
        return-object
        @click="getTagsInput">
        <template v-slot:selection="data">
          <v-chip
            close
            small
            class="font-weight-bold"
            :color="data.item.color"
            :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
            @click:close="removeTagFromSelection(data.item)">
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-chip
            small
            class="font-weight-bold"
            :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
            :color="data.item.color">
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-row class="my-0">
        <v-col>
          <v-select
            disabled
            label="Team"
            v-model="form.team"
            :items="[team]"
            item-text="name"
            return-object/>
        </v-col>
        <v-col>
          <v-select
            v-model="form.stage"
            disabled
            label="Stage"
            :items="[stage]"
            item-text="name"
            return-object>
            <template v-slot:item="{ item }">
              <v-icon
                v-if="item.is_closing"
                small
                left
                color="green lighten-2">
                mdi-check-decagram
              </v-icon>
              {{ item.name }}
            </template>
            <template v-slot:selection="data">
              <v-icon
                v-if="data.item.is_closing"
                small
                left
                color="green lighten-2">
                mdi-check-decagram
              </v-icon>
              {{ data.item.name }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-autocomplete
        label="Assigned To"
        v-model="form.assigned_user"
        :loading="loadingTeamMembers"
        :items="teamMembers"
        item-text="full_name"
        return-object
        clearable
        @click="getTeamMembers(form.team)">
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.full_name"/>
            <v-list-item-subtitle>
              Assigned Tickets: {{ data.item.helpdesk_assigned_tickets_count }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:selection="data">
          <v-list-item-avatar>
            <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.full_name"/>
            <v-list-item-subtitle>
              Assigned Tickets: {{ data.item.helpdesk_assigned_tickets_count }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import ContactCompanyService from '@/services/contact/contact.company.service'
import ContactContactService from '@/services/contact/contact.contact.service'
import HelpdeskTicketTagService from '@/services/helpdesk/helpdesk.ticket.tag.service'
import HelpdeskTicketCategoryService from '@/services/helpdesk/helpdesk.ticket.category.service'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
export default {
  name: 'HelpdeskTicketFormDialog',
  components: { HRichTextEditor },
  props: {
    team: { type: Object },
    stage: { type: Object },
  },
  data () {
    return {
      loadingCompaniesInput: false,
      loadingContactsInput: false,
      loadingCategoriesInput: false,
      loadingTagsInput: false,
      loadingTeamMembers: false,
      tags: [],
      categories: [],
      companies: [],
      contacts: [],
      teamMembers: [],
      priorities: [
        { text: 'Low', value: 'low', icon: 'chevron-up', iconColor: 'green' },
        { text: 'Normal', value: 'normal', icon: 'chevron-double-up', iconColor: 'orange' },
        { text: 'High', value: 'high', icon: 'chevron-triple-up', iconColor: 'red' },
      ],
      form: {
        name: '',
        description: '',
        company: null,
        contact: null,
        category: null,
        priority: 'normal',
        tags: [],
        team: this.team,
        stage: this.stage,
        assigned_user: null,
        attachments: [],
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid || !this.form.description,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
  methods: {
    async getCategoriesInput () {
      this.loadingCategoriesInput = true
      this.categories = await HelpdeskTicketCategoryService.getCategoriesInput()
      this.loadingCategoriesInput = false
    },
    async getCompaniesInput () {
      this.loadingCompaniesInput = true
      this.companies = await ContactCompanyService.getCompaniesInput()
      this.loadingCompaniesInput = false
    },
    changeCompanySelection () {
      this.form.contact = null
    },
    async changeContactSelection () {
      const selectedContact = this.form.contact
      if (selectedContact?.company_id) {
        if (!(this.form.company?.id === selectedContact.company_id)) {
          const company = this.companies.find(company => company?.id === selectedContact.company_id)
          if (company) {
            this.form.company = company
          } else {
            await this.getCompaniesInput()
            // NOTE: Maybe we should consider not querying all the companies here  as input, but only the one needed.
            this.form.company = this.companies.find(company => company?.id === selectedContact.company_id)
          }
        }
      }
    },
    async getContactsInput (company) {
      this.loadingContactsInput = true
      if (company) {
        this.contacts = await ContactCompanyService.getCompanyContactsInput(company.id)
      } else {
        this.contacts = await ContactContactService.getContactsInput()
      }
      this.loadingContactsInput = false
    },
    async getTagsInput () {
      this.loadingTagsInput = true
      this.tags = await HelpdeskTicketTagService.getTagsInput()
      this.loadingTagsInput = false
    },
    removeTagFromSelection (tag) {
      this.form.tags = this.form.tags.filter(item => item.id !== tag.id)
    },
    async getTeamMembers (team) {
      this.loadingTeamMembers = true
      this.teamMembers = await HelpdeskTeamService.getTeamMembers(team.id)
      this.loadingTeamMembers = false
    },
  },
}
</script>

<style scoped>
.v-card::v-deep .v-card__text {
  padding: 0;
}
</style>
