var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoggedIn && _vm.shouldRouteShowHeaderOrSidebar)?_c('h-app-header'):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(
        _vm.isLoggedIn
        && _vm.userMe
        && _vm.isUserSystem
        && _vm.shouldRouteShowHeaderOrSidebar
      )?_c('h-app-system-sidebar'):_vm._e(),(
        _vm.isLoggedIn
        && _vm.userMe
        && !_vm.isUserSystem
        && _vm.shouldRouteShowHeaderOrSidebar
      )?_c('h-app-portal-sidebar'):_vm._e()],1),_c('transition',{attrs:{"name":"router-anim-main","mode":"out-in"}},[_c('router-view',{staticClass:"grey-background"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }