<template>
  <!-- NOTE : Try to remove v-if="team" from this div -->
  <div class="kanban-view" v-if="team && !loadingTeam">
    <!-- TODO: find something else than a naked div -->
    <v-row class="mt-0 mx-0">
      <v-toolbar
        tile
        dense
        elevation="1"
        class="team-toolbar">
        <v-toolbar-title>
          <v-btn
            text
            exact
            :to="{ name: 'HelpdeskTeamFormRead', params: { helpdeskTeamId: team.id } }">
            <v-icon left>mdi-chevron-left</v-icon>
            {{ team.name }}
          </v-btn>
        </v-toolbar-title>
        <v-spacer/>
        <h-filtering-bar :availableFilters="availableFilters"/>
        <v-spacer/>
        <v-btn
          text
          v-if="hasPermission('add_helpdeskticketstage')"
          @click="createStage(team)">
          <v-icon left>mdi-plus</v-icon>
          Add a Stage
        </v-btn>
        <v-progress-linear
          v-if="loadingStages || loadingTickets"
          absolute
          bottom
          height="4"
          color="light-blue darken-4"
          indeterminate/>
      </v-toolbar>
    </v-row>
    <Container
      group-name="stages"
      orientation="horizontal"
      class="kanban-container"
      lock-axis="x"
      drag-handle-selector=".stage-drag-handle"
      non-drag-area-selector=".not-stage-drag-handle"
      drag-class="stage-ghost"
      drop-class="stage-ghost-drop"
      :drop-placeholder="true"
      :get-child-payload="getStagePayload()"
      :tag="{ value: 'div', props: { class: 'row' } }"
      @drop="onStageDrop($event)">
      <Draggable
        v-for="stage in stages"
        :key="stage.id"
        class="kanban-column"
        :tag="{ value: 'div', props: { class: 'col' } }">
        <v-toolbar
          dense
          flat
          tile
          dark
          color="light-blue darken-4"
          class="stage-toolbar"
          :class="{'stage-drag-handle': hasPermission('change_helpdeskticketstage')}">
          <v-toolbar-title class="text-h5">
            <v-tooltip v-if="stage.is_closing" bottom open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  left
                  color="green lighten-2">
                  mdi-check-decagram
                </v-icon>
              </template>
              Closing Stage
            </v-tooltip>
            {{ stage.name }}
          </v-toolbar-title>
          <v-spacer/>
          <v-tooltip v-if="hasPermission('add_helpdeskticket')" top open-delay="250">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="not-stage-drag-handle">
                <v-icon
                  @click="quickCreateTicket(team, stage)"
                  small>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            Add a Ticket
          </v-tooltip>
          <v-menu
            close-on-click
            close-on-content-click
            offsetY
            left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="
                  hasPermission('change_helpdeskticketstage')
                  || hasPermission('delete_helpdeskticketstage')
                "
                v-on="on"
                icon
                class="not-stage-drag-handle">
                <v-icon small>mdi-cog</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                class="not-stage-drag-handle"
                @click="viewStage(stage)">
                <v-icon small>mdi-information</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasPermission('change_helpdeskticketstage')"
                @click="editStage(team, stage)">
                <v-icon small class="mr-4">mdi-pencil</v-icon>
                Edit
              </v-list-item>
              <v-list-item
                v-if="hasPermission('delete_helpdeskticketstage')"
                @click="deleteStage(team, stage)">
                <v-icon small class="mr-4">mdi-delete</v-icon>
                Delete
              </v-list-item>
            </v-list>
          </v-menu>
          <template v-slot:extension>
            <span class="caption">{{ stage.tickets.length }} of {{ paginationByStage[stage.id]?.total_records || stage.tickets.length }}</span>
            <v-spacer/>
            <v-btn
              v-if="paginationByStage[stage.id]?.has_next_page"
              class="not-stage-drag-handle"
              small
              text
              :loading="loadingMoreTickets && loadingMoreTicketsStageId === stage.id"
              @click=" loadingMoreTicketsStageId=stage.id; loadMoreTicketsForStage(stage)">
              <v-icon left small>mdi-download</v-icon>
              Load More
            </v-btn>
          </template>
        </v-toolbar>
        <Container
          group-name=tickets
          drag-handle-selector=".ticket-drag-handle"
          non-drag-area-selector=".not-ticket-drag-handle"
          drag-class="ticket-ghost"
          drop-class="ticket-ghost-drop"
          :drop-placeholder="true"
          :get-child-payload="getTicketPayload(stage)"
          @drop="(event) => onTicketDrop(team, stage, event)">
          <Draggable v-for="ticket in stage.tickets" :key="ticket.id">
            <v-card
              tile
              outlined
              class="ticket-card"
              :class="{'ticket-drag-handle': hasPermission('change_helpdeskticket')}"
              :ripple="false"
              @click="onTicketClick(ticket)">
              <v-card-title class="subtitle-2">
                {{ ticket.name }}
              </v-card-title>
              <v-card-subtitle v-if="ticket.category">
                <v-chip
                  small
                  label
                  outlined
                  class="font-weight-bold"
                  :color="ticket.category?.color">
                  {{ ticket.category?.name }}
                </v-chip>
              </v-card-subtitle>
              <v-card-subtitle v-if="ticket.contact" class="mt-1 py-0 caption">
                <v-icon small left>mdi-account</v-icon>
                {{ ticket.contact.full_identification }}
              </v-card-subtitle>
              <v-card-subtitle v-else-if="ticket.company" class="mt-1 py-0 caption">
                <v-icon small left>mdi-office-building</v-icon>
                {{ ticket.company.name }}
              </v-card-subtitle>
              <v-card-subtitle>
                <v-chip-group column>
                  <v-chip
                    v-for="tag in ticket.tags"
                    :key="tag.id"
                    x-small
                    class="font-weight-bold"
                    :color="tag.color"
                    :class="{ 'white--text': shouldTextBeWhite(tag.color) }">
                    {{ tag.name }}
                  </v-chip>
                </v-chip-group>
              </v-card-subtitle>
              <v-card-actions class="mx-1 pb-1">
                <v-tooltip v-if="isTimesheetsAppActivated" top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="ticket.timesheets_count ? 'blue darken-1' : '#E0E0E0'">
                          mdi-clipboard-text-clock
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  {{ ticket.timesheets_count }} {{ timesheetsIconText(ticket) }}
                </v-tooltip>
                <v-tooltip top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="ticket.attachments_count ? 'blue darken-1' : '#E0E0E0'">
                          mdi-paperclip
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  {{ ticket.attachments_count }} {{ attachmentsIconText(ticket) }}
                </v-tooltip>
                <v-tooltip top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="subtasksAttributes(ticket).color">
                          mdi-{{ subtasksAttributes(ticket).icon }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span v-if="ticket.subtasks_count > 0">{{ ticket.subtasks_done_count }} of {{ ticket.subtasks_count }} {{ subtasksIconText(ticket) }}</span>
                  <span v-else>0 subtask</span>
                </v-tooltip>
                <v-tooltip top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="ticket.has_answer ? 'green' : '#E0E0E0'">
                          mdi-comment-check
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span v-if="ticket.has_answer">Answered</span>
                  <span v-else>Unanswered</span>
                </v-tooltip>
                <v-tooltip top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="slaStatusAttributes(ticket).color">
                          mdi-{{ slaStatusAttributes(ticket).icon }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  {{ slaStatusAttributes(ticket).text }}
                </v-tooltip>
                <v-tooltip top open-delay="200">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        tile
                        icon
                        class="not-ticket-drag-handle"
                        @click.stop>
                        <v-icon
                          small
                          :color="ticket.is_closed ? 'green' : '#E0E0E0'">
                          mdi-{{ ticket.is_closed ? 'check-circle' : 'checkbox-blank-circle-outline' }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  {{ ticket.is_closed ? 'Closed' : 'Open' }}
                </v-tooltip>
                <v-spacer/>
                <v-menu
                  offsetY
                  left
                  close-on-click
                  close-on-content-click>
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip left open-delay="200">
                      <template v-slot:activator="{ on: tooltip }">
                        <span v-on="tooltip">
                          <v-btn
                            v-on="menu"
                            tile
                            icon
                            class="not-ticket-drag-handle"
                            :class="{ 'disable-events': !hasPermission('change_helpdeskticket') }">
                            <v-icon small :color="priorityAttributes(ticket.priority).color">mdi-{{ priorityAttributes(ticket.priority).icon }}</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      {{ priorityAttributes(ticket.priority).text }} Priority
                    </v-tooltip>
                  </template>
                  <v-list dense class="pa-0">
                    <v-list-item-group>
                      <v-list-item
                        v-for="priority in priorities"
                        :key="priority.value"
                        @click="changeTicketPriority(team, stage, ticket, priority)">
                        <v-list-item-icon>
                          <v-icon :color="priorityAttributes(priority.value).color">
                            mdi-{{ priorityAttributes(priority.value).icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          {{ priorityAttributes(priority.value).text }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-menu
                  offsetY
                  left
                  close-on-click
                  close-on-content-click>
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on: tooltip }">
                        <span v-on="tooltip">
                          <v-btn
                            v-on="menu"
                            tile
                            icon
                            class="not-ticket-drag-handle"
                            :class="{ 'disable-events': !hasPermission('change_helpdeskticket') }"
                            @click="getTeamMembers(team)">
                            <v-icon v-if="!ticket.assigned_user" small>mdi-account-plus-outline</v-icon>
                            <v-avatar v-else size="22px" >
                              <v-img :src="ticket.assigned_user.picture ? getPictureURL(ticket.assigned_user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                            </v-avatar>
                          </v-btn>
                        </span>
                      </template>
                      <span v-if="ticket.assigned_user">{{ ticket.assigned_user.full_name }}</span>
                      <span v-else-if="hasPermission('change_helpdeskticket')">Assign User</span>
                      <span v-else>Not Assigned</span>
                    </v-tooltip>
                  </template>
                  <v-list
                    dense
                    two-line
                    flat
                    class="user-list"
                    :style="{
                      'overflow-y': teamMembers.length > 4 ? 'scroll' : 'unset'
                    }">
                    <v-list-item-group>
                      <v-list-item
                        v-if="ticket.assigned_user"
                        @click="unassignUser(team, stage, ticket)"
                        class="not-assigned-user">
                        <v-list-item-avatar>
                          <v-icon>mdi-account-remove</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          Unassign
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="mePartOfTeam && !ticketIsAssignedToMe(ticket)"
                        @click="assignToMe(team, stage, ticket)"
                        class="not-assigned-user">
                        <v-list-item-avatar>
                          <v-icon>mdi-account-arrow-left</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          Assign to Me
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="teamMembers.length"
                        class="my-2"/>
                      <v-list-item
                        v-for="member in teamMembers"
                        :key="member.id"
                        @click="assignUser(team, stage, ticket, member)"
                        :class="{
                          'current-assigned-user': ticket.assigned_user && ticket.assigned_user.id === member.id,
                          'not-assigned-user': !ticket.assigned_user || ticket.assigned_user.id !== member.id
                        }">
                        <v-list-item-avatar>
                          <v-img :src="member.picture ? getPictureURL(member.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="member.full_identification"/>
                          <v-list-item-subtitle>
                            Assigned Tickets: {{ member.helpdesk_assigned_tickets_count }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </Draggable>
        </Container>
        <v-btn
          v-if="paginationByStage[stage.id]?.has_next_page"
          block
          text
          small
          color="blue darken-2"
          :loading="loadingMoreTickets && loadingMoreTicketsStageId === stage.id"
          @click=" loadingMoreTicketsStageId=stage.id; loadMoreTicketsForStage(stage)">
          <v-icon left small>mdi-download</v-icon>
          Load more...
        </v-btn>
      </Draggable>
    </Container>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import HelpdeskTicketStageFormDialog from '../stage/shared/HelpdeskTicketStageFormDialog'
import HelpdeskTicketFormDialog from './shared/HelpdeskTicketFormDialog'
import WarningModal from '@/common/components/WarningModal'
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
import HFilteringBar from '@/common/components/HFilteringBar'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'HelpdeskTicketKanban',
  components: {
    Container,
    Draggable,
    HFilteringBar,
    HRequestLoading
  },
  props: {
    helpdeskTeamId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  data () {
    return {
      team: null,
      stages: [],
      teamMembers: [],
      loadingTeam: false,
      loadingStages: false,
      loadingTickets: false,
      loadingMoreTickets: false,
      loadingMoreTicketsStageId: null,
      paginationByStage: {},
      priorities: [
        { text: 'Low', value: 'low' },
        { text: 'Normal', value: 'normal' },
        { text: 'High', value: 'high' }
      ],
      availableFilters: [
        { fieldName: 'name', label: 'Name', type: 'string' },
        { fieldName: 'author', label: 'Author', type: 'string' },
        { fieldName: 'assignee', label: 'Assignee', type: 'string' },
        { fieldName: 'unassigned', label: 'Unassigned', optionText: 'Unassigned', type: 'boolean', value: true },
        { fieldName: 'contact', label: 'Contact', type: 'string' },
        { fieldName: 'company', label: 'Company', type: 'string' },
        { fieldName: 'tag', label: 'Tag', type: 'string' },
        { fieldName: 'category', label: 'Category', type: 'string' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'low', valueDisplay: 'Low' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'normal', valueDisplay: 'Normal' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'high', valueDisplay: 'High' },
        { fieldName: 'answered', label: 'Answered', optionText: 'Answered', type: 'boolean', value: true },
        { fieldName: 'answered', label: 'Answered', optionText: 'Unanswered', type: 'boolean', value: false }
      ]
    }
  },
  async mounted () {
    if (this.helpdeskTeamId) {
      await this.getTeam(this.helpdeskTeamId)
      if (this.team) {
        await this.getTeamStages(this.helpdeskTeamId)
        if (this.stages) {
          await this.getAllTeamStageTickets(this.team, this.stages, this.$store.state.url.urlSearchParams)
        }
      }
    }
  },
  watch: {
    async '$store.state.url.urlSearchParams' (newParams, oldParams) {
      if (this.team && this.stages) {
        await this.getAllTeamStageTickets(this.team, this.stages, newParams)
      }
    }
  },
  computed: {
    mePartOfTeam () {
      const meId = this.$store.state.login.me.id
      return this.teamMembers.map(member => member.id).includes(meId)
    },
    isTimesheetsAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === "timesheets") !== undefined
    },
  },
  methods: {
    allTicketSubtasksDone (ticket) {
      return ticket.subtasks_done_count === ticket.subtasks_count
    },
    ticketIsAssignedToMe (ticket) {
      const meId = this.$store.state.login.me.id
      return ticket.assigned_user?.id === meId
    },
    timesheetsIconText (ticket) {
      if (ticket.timesheets_count > 1) return 'Timesheets'
      return 'Timesheet'
    },
    attachmentsIconText (ticket) {
      if (ticket.attachments_count > 1) return 'Attachments'
      return 'Attachment'
    },
    subtasksIconText (ticket) {
      if (ticket.subtasks_count > 1) {
        return 'Subtasks done'
      } else {
        return 'Subtask done'
      }
    },
    slaStatusAttributes (ticket) {
      if (ticket.sla_status === 'ok') return { icon: 'clock-check', color: 'green', text: 'SLA Ok' }
      if (ticket.sla_status === 'warning') return { icon: 'clock-alert', color: 'amber lighten-1', text: 'SLA Warning' }
      if (ticket.sla_status === 'alert') return { icon: 'clock-alert', color: 'orange darken-1', text: 'SLA Alert' }
      if (ticket.sla_status === 'failed') return { icon: 'clock-remove', color: 'red', text: 'SLA Failed' }
    },
    priorityAttributes (priority) {
      if (priority === 'low') return { icon: 'chevron-up', color: 'green', text: 'Low' }
      if (priority === 'normal') return { icon: 'chevron-double-up', color: 'orange', text: 'Normal' }
      if (priority === 'high') return { icon: 'chevron-triple-up', color: 'red', text: 'High' }
    },
    subtasksAttributes (ticket) {
      if (ticket.subtasks_count === 0) {
        return { icon: 'check-circle', color: '#E0E0E0' }
      } else if (!this.allTicketSubtasksDone(ticket)) {
        return { icon: 'progress-wrench', color: 'orange' }
      } else {
        return { icon: 'check-circle', color: 'green' }
      }
    },
    async getTeam (teamId) {
      this.loadingTeam = true
      this.team = await HelpdeskTeamService.getTeam(teamId)
      this.loadingTeam = false
    },
    async getTeamStages (teamId) {
      this.loadingStages = true
      this.stages = await HelpdeskTeamService.getTeamStages(teamId)
      for (let stage of this.stages) {
        this.$set(stage, 'tickets', [])
      }
      this.loadingStages = false
    },
    async getAllTeamStageTickets (team, stages, urlSearchParams) {
      this.loadingTickets = true
      const responsesDatas = await HelpdeskTeamService.getAllTeamStageTickets(team.id, stages.map(stage => stage.id), urlSearchParams)
      for (const stage of stages) {
        this.paginationByStage[stage.id] = responsesDatas[stage.id].pagination
        stage.tickets = responsesDatas[stage.id].tickets
        this.stages.splice(this.stages.indexOf(stage), 1, stage)
      }
      this.loadingTickets = false
    },
    // async getStageTickets (stage, urlSearchParams) {
    //   const responseData = await HelpdeskTeamService.getTeamStageTickets(this.helpdeskTeamId, stage.id, urlSearchParams)
    //   if (responseData) {
    //     this.paginationByStage[stage.id] = responseData.pagination
    //     stage.tickets = responseData.tickets
    //     this.stages.splice(this.stages.indexOf(stage), 1, stage)
    //   }
    // },
    async loadMoreTicketsForStage (stage) {
      const stagePagination = this.paginationByStage[stage.id]
      if (stagePagination?.has_next_page) {
        this.loadingMoreTickets = true
        const nextPageNumber = stagePagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await HelpdeskTeamService.getTeamStageTickets(this.helpdeskTeamId, stage.id, urlSearchParams)
        this.paginationByStage[stage.id] = responseData.pagination
        stage.tickets = stage.tickets.concat(responseData.tickets)
        this.stages.splice(this.stages.indexOf(stage), 1, stage)
        this.loadingMoreTickets = false
      }
    },
    async getTeamMembers (team) {
      this.teamMembers = await HelpdeskTeamService.getTeamMembers(team.id)
    },
    createStage (team) {
      this.$dialog.show(HelpdeskTicketStageFormDialog, {
        mode: 'add',
        persistent: true,
        waitForResult: true
      }).then(async result => {
        if (result) {
          const createdStage = await HelpdeskTeamService.createTeamStage(team.id, {
            name: result.name,
            is_closing: result.is_closing
          })
          if (createdStage) {
            this.$set(createdStage, 'tickets', [])
            this.stages.push(createdStage)
            this.$dialog.notify.success(
              `Stage '${createdStage.name}' created`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    viewStage (stage) {
      this.$dialog.show(HelpdeskTicketStageFormDialog, {
        mode: 'view',
        stage: stage,
        persistent: true
      }).catch(error => {
        console.log(error)
        // TODO: Do something with the error, rather than printing to console
      })
    },
    editStage (team, stage) {
      this.$dialog.show(HelpdeskTicketStageFormDialog, {
        persistent: true,
        waitForResult: true,
        mode: 'change',
        stage: stage
      }).then(async result => {
        if (result) {
          let updatedStage = await HelpdeskTeamService.updateTeamStage(team.id, stage.id, {
            name: result.name,
            is_closing: result.is_closing
          })
          if (updatedStage) {
            updatedStage.tickets = stage.tickets
            this.stages.splice(this.stages.indexOf(stage), 1, updatedStage)
            this.$dialog.notify.success(
              'Stage updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteStage (team, stage) {
      if (stage.tickets.length > 0) {
        this.$dialog.show(WarningModal, {
          title: 'Cannot delete Stage',
          message: 'It is impossible to delete a Stage that contains Tickets.',
          advice: "Empty the Stage '" + stage.name + "' before deleting it.",
          persistent: true
        })
      } else {
        this.$dialog.show(DeleteModal, {
          recordName: 'Stage',
          recordNamePlural: 'Stages',
          persistent: true,
          waitForResult: true
        }).then(async yes => {
          if (yes) {
            const response = await HelpdeskTeamService.deleteTeamStage(team.id, stage.id)
            if (response) {
              this.stages.splice(this.stages.indexOf(stage), 1)
              this.$dialog.notify.success(
                `Stage '${stage.name}' deleted`, {
                  position: 'top-right',
                  border: 'left',
                  dense: true,
                  timeout: 5000
                }
              )
            }
          }
        })
      }
    },
    getStagePayload () {
      return index => {
        return this.stages[index]
      }
    },
    async onStageDrop (dropResult) {
      if (dropResult.addedIndex !== dropResult.removedIndex) {
        this.stages = this.applyDrag(this.stages, dropResult)
        const response = await HelpdeskTeamService.updateTeamStage(this.helpdeskTeamId, dropResult.payload.id, {
          sequence: dropResult.addedIndex
        })
        if (response) {
          this.$dialog.notify.success(
            `Stage '${dropResult.payload.name}' order updated`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000
            }
          )
        } else {
          this.stages = this.revertDrag(this.stages, dropResult)
        }
      }
    },
    quickCreateTicket (team, stage) {
      this.$dialog.show(HelpdeskTicketFormDialog, {
        persistent: true,
        waitForResult: true,
        team: team,
        stage: stage
      }).then(async result => {
        if (result) {
          const formData = new FormData()
          formData.append('name', result.name)
          formData.append('description', result.description)
          formData.append('priority', result.priority)
          formData.append('tag_ids', result.tags ? result.tags.map(tag => tag.id) : [])
          formData.append('team_id', result.team.id)
          formData.append('stage_id', result.stage.id)
          if (result.category) formData.append('category_id', result.category.id)
          if (result.company) formData.append('company_id', result.company.id)
          if (result.contact) formData.append('contact_id', result.contact.id)
          if (result.assigned_user) formData.append('assigned_user_id', result.assigned_user.id)
          for (const attachment of result.attachments) {
            formData.append('attachments', attachment)
          }
          const createdTicket = await HelpdeskTeamService.createTeamStageTicket(team.id, stage.id, formData)
          if (createdTicket) {
            stage.tickets.push(createdTicket)
            this.paginationByStage[stage.id].total_records += 1
            this.$dialog.notify.success(
              'Ticket created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    getTicketPayload (stage) {
      return index => {
        return stage.tickets[index]
      }
    },
    onTicketClick (ticket) {
      this.$router.push({
        name: 'HelpdeskTicketFormRead',
        params: { helpdeskTicketId: ticket.id }
      })
    },
    async onTicketDrop (team, stage, dropResult) {
      stage.tickets = this.applyDrag(stage.tickets, dropResult)
      if ((dropResult.removedIndex === null || dropResult.addedIndex === null) && dropResult.removedIndex !== dropResult.addedIndex) { // Means we moved to another column
        if (dropResult.addedIndex !== null) {                                                                                          // Column where it has moved
          const ticketStageIdBeforeUpdate = dropResult.payload.stage_id
          const changedTicket = await HelpdeskTeamService.updateTeamStageTicket(team.id, stage.id, dropResult.payload.id, {
            stage_id: stage.id
          })
          if (changedTicket) {
            this.updateDataAfterTicketUpdate(stage, dropResult.payload, changedTicket)
            this.paginationByStage[ticketStageIdBeforeUpdate].total_records -= 1
            this.paginationByStage[stage.id].total_records += 1
            this.$dialog.notify.success(
              `Ticket moved to '${stage.name}'`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          } else {
            const stageToRevertDragTo = this.stages.filter(stage => { return stage.id === ticketStageIdBeforeUpdate })[0]
            stageToRevertDragTo.tickets = this.applyDrag(stageToRevertDragTo.tickets, dropResult)
            stage.tickets = this.revertDrag(stage.tickets, dropResult)
          }
        }
      }
    },
    applyDrag (collection, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return collection // Collection not affected, return as it is
      const result = [...collection]
      let movedElement = payload
      if (removedIndex !== null) {                                        // Collection from which we remove, remove element from it
        movedElement = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {                                          // Collection in which we add, add element to it
        result.splice(addedIndex, 0, movedElement)
      }
      return result                                                       // Return affected Collection
    },
    revertDrag (collection, dropResult) {
      [dropResult.addedIndex, dropResult.removedIndex] = [dropResult.removedIndex, dropResult.addedIndex]
      return this.applyDrag(collection, dropResult)
    },
    async updateDataAfterTicketUpdate (stage, ticket, updatedTicket) {
      stage.tickets.splice(stage.tickets.indexOf(ticket), 1, updatedTicket)
      // TODO: Do we want to refilter all stage when updating 1 ticket ?
      //       Certainly not... We have to find a new alternative.
      //       But filtering like in backend would be very difficult...
      // const currentFilters = this.$store.state.url.urlSearchParams
      // if (Array.from(currentFilters.entries()).length) {
      //   this.loadingTickets = true
      //   // await this.getStageTickets(stage, currentFilters)
      //   this.loadingTickets = false
      // } else {
      //   stage.tickets.splice(stage.tickets.indexOf(ticket), 1, updatedTicket)
      // }
    },
    async changeTicketPriority (team, stage, ticket, priority) {
      if (ticket.priority !== priority.value) {
        const updatedTicket = await HelpdeskTeamService.updateTeamStageTicket(team.id, stage.id, ticket.id, {
          priority: priority.value
        })
        if (updatedTicket) {
          this.updateDataAfterTicketUpdate(stage, ticket, updatedTicket)
          this.$dialog.notify.success(
            `Ticket priority changed to '${priority.text}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000
            }
          )
        }
      }
    },
    async unassignUser (team, stage, ticket) {
      if (ticket.assigned_user) {
        const updatedTicket = await HelpdeskTeamService.updateTeamStageTicket(team.id, stage.id, ticket.id, {
          assigned_user_id: null
        })
        if (updatedTicket) {
          this.updateDataAfterTicketUpdate(stage, ticket, updatedTicket)
          this.$dialog.notify.success(
            'Ticket unassigned', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000
            }
          )
        }
      }
    },
    async assignToMe (team, stage, ticket) {
      const meId = this.$store.state.login.me.id
      if (!ticket.assigned_user || ticket.assigned_user.id !== meId) {
        const updatedTicket = await HelpdeskTeamService.updateTeamStageTicket(team.id, stage.id, ticket.id, {
          assigned_user_id: meId
        })
        if (updatedTicket) {
          this.updateDataAfterTicketUpdate(stage, ticket, updatedTicket)
          this.$dialog.notify.success(
            'Successfully assigned you to this ticket', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000
            }
          )
        }
      }
    },
    async assignUser (team, stage, ticket, user) {
      if (!ticket.assigned_user || ticket.assigned_user.id !== user.id) {
        const updatedTicket = await HelpdeskTeamService.updateTeamStageTicket(team.id, stage.id, ticket.id, {
          assigned_user_id: user.id
        })
        if (updatedTicket) {
          this.updateDataAfterTicketUpdate(stage, ticket, updatedTicket)
          this.$dialog.notify.success(
            `Ticket assigned to '${user.full_identification}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000
            }
          )
        }
      }
    },
  },
}
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  div.kanban-view {
    height: inherit;
  }
  .v-toolbar.team-toolbar {
    height: unset!important;
  }
  .v-toolbar.team-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .kanban-container {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    overflow: auto;
  }
  .v-toolbar.stage-toolbar {
    z-index: 4;
  }
  .stage-drag-handle {
    cursor: move;
  }
  .stage-ghost {
    cursor: move;
    transition: transform 0.18s ease;
    transform: scale(1.04);
  }
  .stage-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: scale(1);
  }
  .smooth-dnd-container.vertical {
    min-height: 800px;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
  }
  .v-card--link:before {
    background-color: inherit;
  }
  .v-card__title {
    padding: 8px;
    margin-bottom: 2px;
  }
  .v-card__subtitle {
    padding: 4px 8px;
  }
  .v-card__title+.v-card__subtitle {
    margin-top: -8px;
  }
  .v-card__actions {
    padding: 0px;
  }
  .v-card__actions > .v-btn {
    padding: 0px;
  }
  .v-btn--icon.v-size--default {
    height: unset;
    width: unset;
  }
  .v-list.user-list {
    padding: 0;
    max-height: 360px;
  }
  .v-item-group {
    max-width: fit-content;
  }
  .v-list-item.not-assigned-user:hover {
    background-color: #F8F8F8;
  }
  .v-list-item.current-assigned-user {
    background-color: #e0f0ff;
  }
  .ticket-card {
    transition: all 0.15s;
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto
  }
  .ticket-card:hover {
    border: thin solid rgba(0, 0, 0, 0.35);
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ticket-ghost {
    cursor: grabbing;
    transition: transform 0.18s ease;
    transform: rotateZ(3.5deg);
  }
  .ticket-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg);
  }
</style>
