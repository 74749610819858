<template>
  <div v-if="!loading">
    <v-subheader class="mb-2">
      <h2>{{ $t('common.labels.emailConfigClassicOutbound') }}</h2>
    </v-subheader>
    <v-toolbar
      v-if="editing"
      flat
      height=36
      color="grey lighten-4"
      class="mb-6">
      <v-toolbar-items>
        <v-btn
          text
          color="primary"
          :loading="loadingTest"
          :disabled="!isFormValid || !formHasChanges"
          @click="testSettings()">
          {{ $t('common.buttons.testConnection') }}
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          color="green"
          :disabled="!formSuccessfullyTested || !isFormValid"
          @click="updateSettings()">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-alert
      v-if="editing && formHasChanges"
      outlined
      text
      dense
      class="mx-4 mt-4 mb-2"
      :type="connectionAlert.type">
      {{ connectionAlert.text }}
    </v-alert>
    <v-form v-model="isFormValid" class="pa-4" :disabled="!editing">
      <v-row>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.host"
            required
            clearable
            autofocus
            type="text"
            maxlength="255"
            :label="$tc('common.labels.hosts', 1)"
            :counter="editing"
            :rules="[
              formRules.required,
              formRules.domain
            ]"/>
        </v-col>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.port"
            required
            clearable
            type="number"
            :label="$tc('common.labels.ports', 1)"
            :rules="[
              formRules.required,
              formRules.aboveMinPort,
              formRules.belowMaxPort,
            ]"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.username"
            clearable
            type="text"
            maxlength="255"
            :label="$t('common.labels.username')"
            :counter="editing"/>
        </v-col>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.password"
            clearable
            maxlength="255"
            :label="$t('common.labels.password')"
            :counter="editing"
            :type="showPassword ? 'text' : 'password'"
            :rules="[formRules.required]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"/>
        </v-col>
      </v-row>
      <v-switch
        v-model="form.use_tls"
        inset
        dense
        color="green"
        :label="$t('common.labels.useTLS')"
        :rules="[formRules.tlsExclusiveSSL]"/>
      <v-switch
        v-model="form.use_ssl"
        inset
        dense
        color="green"
        :label="$t('common.labels.useSSL')"
        :rules="[formRules.sslExclusiveTLS]"/>
      <v-text-field
        v-model="form.default_from"
        placeholder="support@example.be"
        maxlength="254"
        persistent-hint
        :hint="$t('applications.base.settings.emails.classic.outbound.hints.defaultEmailFrom')"
        :label="$t('common.labels.defaultEmailFrom')"
        :counter="editing"
        :rules="[formRules.required]"/>
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
import BaseSettingsService from '@/services/base/base.settings.service'
import WarningModal from '@/common/components/WarningModal'
export default {
  name: 'EmailSettingsClassicOutboundForm',
  mixins: [ DialogUnsavedChanges ],
  components: {
    HRequestLoading
  },
  props: {
    editing: { type: Boolean, required: true },
  },
  data () {
    return {
      loading: false,
      loadingTest: false,
      outboundMailServer: null,
      form: {
        host: '',
        port: 0,
        username: '',
        password: '',
        use_ssl: false,
        use_tls: false,
        default_from: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        belowMaxPort: value => value <= 65535 || this.$t('common.errors.invalidPortBelow'),
        aboveMinPort: value => value >= 0 || this.$t('common.errors.invalidPortAbove'),
        domain: value => {
          const pattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/
          return !value || pattern.test(value) || this.$t('common.errors.invalidHostname')
        },
        tlsExclusiveSSL: value => ((value === !this.form.use_ssl) && !this.form.use_ssl) || !value || this.$t('common.errors.invalidSSLAndTLSExclusive'),
        sslExclusiveTLS: value => ((value === !this.form.use_tls) && !this.form.use_tls) || !value || this.$t('common.errors.invalidSSLAndTLSExclusive'),
      },
      showPassword: false,
      formTested: false,
      formSuccessfullyTested: false
    }
  },
  async mounted () {
    await this.getClassicOutboundEmailSettings()
    if (this.outboundMailServer) {
      this.initFormFields()
    }
  },
  watch: {
    'form': {
      handler (form) {
        this.formTested = false
        this.formSuccessfullyTested = false
      },
      deep: true,
    },
  },
  computed: {
    formHasChanges () {
      if (this.outboundMailServer) {
        return Object.keys(this.form).some(field => this.form[field] !== this.outboundMailServer[field])
      }
      return false
    },
    connectionAlert () {
      if (this.formHasChanges && !this.formTested) {
        return {
          type: 'info',
          text: this.$t('applications.base.settings.emails.common.hints.testConnection'),
        }
      } else if (this.formHasChanges && this.formTested && !this.formSuccessfullyTested) {
        return {
          type: 'error',
          text: this.$t('applications.base.settings.emails.common.hints.failedConnection'),
        }
      } else {
        return {
          type: 'success',
          text: this.$t('applications.base.settings.emails.common.hints.successConnection'),
        }
      }
    },
  },
  methods: {
    initFormFields () {
      for (let field in this.form) {
        if (field === 'password') continue
        this.form[field] = this.outboundMailServer[field]
      }
    },
    cancelEdit () {
      this.form.password = ''
      this.$emit('cancelEdit')
    },
    async getClassicOutboundEmailSettings () {
      this.loading = true
      this.outboundMailServer = await BaseSettingsService.getClassicOutboundEmailSettings()
      this.loading = false
    },
    async updateSettings () {
      const { host, port, username, password, use_tls, use_ssl, default_from } = this.$data.form
      this.$dialog.show(WarningModal, {
        icon: 'pencil',
        title: this.$t('applications.base.settings.emails.common.updateEmailSettingsTitle'),
        message: this.$t('applications.base.settings.emails.common.updateEmailSettingsText'),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const updatedSettings = await BaseSettingsService.createOrUpdateClassicOutboundEmailSettings({
            host: host,
            port: port,
            username: username,
            password: password,
            use_tls: use_tls,
            use_ssl: use_ssl,
            default_from: default_from,
          })
          if (updatedSettings) {
            this.cancelEdit()
            this.outboundMailServer = updatedSettings
            this.$dialog.notify.success(
              this.$t('applications.base.settings.emails.classic.messages.outboundUpdated'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async testSettings () {
      this.loadingTest = true
      const { host, port, username, password, use_tls, use_ssl } = this.$data.form
      const testResult = await BaseSettingsService.checkClassicOutboundEmailSettings({
        host: host,
        port: port,
        username: username,
        password: password,
        use_tls: use_tls,
        use_ssl: use_ssl,
      })
      if (testResult) {
        this.formTested = true
        if (testResult.success === true) {
          this.formSuccessfullyTested = true
        } else {
          this.formSuccessfullyTested = false
        }
      }
      this.loadingTest = false
    },
  },
}
</script>
