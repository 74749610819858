export default {
  all: 'All',
  for: 'for',
  loading: 'Loading',
  of: 'of',
  selectionOnly: 'Selected Only',
  showing: 'Showing',
  applicationNames: {
    base: 'Settings',
    contact: 'Contacts',
    helpdesk: 'Helpdesk',
    inventory: 'Inventory',
    timesheets: 'Timesheets',
  },
  applications: {
    base: {
      applications: {
        messages: {
          applicationActivated: 'Application \'{name}\' activated',
          applicationDeactivated: 'Application \'{name}\' deactivated',
        },
      },
      changeUserPasswordDialog: {
        changePasswordOf: 'Change {fullName}\'s password',
      },
      groups: {
        common: {
          deleteGroupTitle: 'Delete group \'{name}\' ?',
          deleteGroupWarningText: 'Are you sure you want to delete group \'{name}\' ?',
          deleteGroupsTitle: 'Delete selected group ? | Delete selected group ? | Delete selected groups ?',
          deleteGroupsWarningText: 'Are you sure you want to delete this group ? | Are you sure you want to delete this group ? | Are you sure you want to delete these groups ?',
        },
        form: {
          usersTab: {
            addUserButton: 'Add this user | Add this user | Add these users',
            searchUsers: 'Search user...',
            selectUsersToAdd: 'Select users to add...',
          },
        },
        messages: {
          groupCreated: 'Group \'{name}\' created',
          groupDeleted: 'Group \'{name}\' deleted',
          groupUpdated: 'Group \'{name}\' updated',
          groupsDeleted: 'Selected group has been deleted | Selected group has been deleted | The {count} selected groups have been deleted',
          permissionsUpdated: 'Permissions updated',
          usersAddedToGroup: '{count} user added to group \'{name}\' | {count} user added to group \'{name}\' | {count} users added to group \'{name}\'',
          userRemovedFromGroup: 'User \'{fullName} ({username})\' removed from group \'{name}\'',
        },
      },
      loginForm: {
        forgottenPassword: 'I forgot my password',
        login: 'Login',
        loginBtnText: 'Login',
        switchBtnText: 'Switch user',
        welcomeBack: 'Welcome back,',
      },
      menus: {
        logout: 'Log out',
        myProfile: 'My Profile',
      },
      resetPasswordForm: {
        backToLoginButton: 'Back to Login',
        requestSuccess: 'Password successfully changed',
        resetPassword: 'Reset Password',
        sendButton: 'Send',
      },
      resetPasswordRequestForm: {
        backToLoginButton: 'Back to Login',
        confirmationText: 'If the provided email address was valid, you will shortly receive an email to explain you the steps to reset your password.',
        forgottenPassword: 'Forgotten Password',
        infoTooltip: 'Enter the email address corresponding to your account and we will send you a link to reset your password.',
        requestReceived: 'We received your request',
        sendButton: 'Send',
      },
      settings: {
        emails: {
          classic: {
            inbound: {
              createInboundTitle: 'Add inbound mailbox',
              deleteInboundMailboxTitle: 'Delete mailbox \'{name}\' ?',
              deleteInboundMailboxWarningText: 'Are you sure you want to delete this inbound mailbox ?',
              updateInboundTitle: 'Update inbound mailbox',
            },
            messages: {
              inboundCreated: 'Inbound mailbox added',
              inboundDeleted: 'Inbound mailbox deleted',
              inboundUpdated: 'Inbound mailbox updated',
              outboundUpdated: 'Outbound email settings updated'
            },
            outbound: {
              hints: {
                defaultEmailFrom: 'Will be used as the default address for sending emails.',
              },
            },
          },
          common: {
            hints: {
              testConnection: 'Please test whether the connection is working or not before saving.',
              failedConnection: 'The provided connection information did not work.',
              successConnection: 'The provided connection information successfully connected.',
            },
            updateEmailSettingsTitle: 'Update email settings ?',
            updateEmailSettingsText: 'Are you sure you want to save these email settings ?',
          },
          office365: {
            configurationTitle: 'Office 365 Configuration',
            hints: {
              defaultEmailFrom: 'Will be used as the default address for sending emails. You must use an existing address/mailbox.',
            },
            messages: {
              office365Updated: 'Office 365 email settings updated',
            },
          },
          outboundFailedEmails: {
            deleteOutboundFailedEmailAlertText: 'It will also delete the associated message (e.g.: Ticket message).',
            deleteOutboundFailedEmailTitle: 'Delete this failed email ?',
            deleteOutboundFailedEmailWarningText: 'Are you sure you want to delete this failed email ?',
            messages: {
              failedEmailDeleted: 'Failed email deleted',
            },
          },
        },
        integrations: {
          apikeys: {
            common: {
              activateApikeyTitle: 'Activate \'{name}\' API key ?',
              activateApikeyText: 'Are you sure you want to activate \'{name}\' API key ?',
              deactivateApikeyTitle: 'Deactivate \'{name}\' API key ?',
              deactivateApikeyText: 'Are you sure you want to deactivate \'{name}\' API key ?',
              deleteApikeyTitle: 'Delete \'{name}\' API key ?',
              deleteApikeyText: 'Are you sure you want to delete \'{name}\' API key ?',
              rotateApikeyTitle: 'Rotate \'{name}\' API key ?',
              rotateApikeyText: 'Are you sure you want to rotate \'{name}\' API key ?',
            },
            form: {
              hints: {
                useMeaningfulName: 'Use a meaningful name that represents the usage that will be made. e.g.: \'Zabbix\'.',
              },
            },
            messages: {
              apikeyActivated: 'API key \'{name}\' activated',
              apikeyCreated: 'API key \'{name}\' created',
              apikeyDeactivated: 'API key \'{name}\' deactivated',
              apikeyDeleted: 'API key \'{name}\' deleted',
              apikeyRotated: 'API key \'{name}\' rotated',
              apikeyTokenCopiedToClipboard: 'Token copied to clipboard',
              apiUserCreated: 'API User \'{fullName}\' created',
            },
          },
          webhooks: {
            actions: {
              createOrUpdateHelpdeskTicket: 'Create or update Helpdesk ticket',
            },
            common: {
              activateWebhookTitle: 'Activate \'{name}\' webhook ?',
              activateWebhookText: 'Are you sure you want to activate \'{name}\' webhook ?',
              deactivateWebhookTitle: 'Deactivate \'{name}\' webhook ?',
              deactivateWebhookText: 'Are you sure you want to deactivate \'{name}\' webhook ?',
              deleteWebhookTitle: 'Delete \'{name}\' webhook ?',
              deleteWebhookWarningText: 'Are you sure you want to delete \'{name}\' webhook ?',
            },
            form: {
              hints: {
                useMeaningfulName: 'Use a meaningful name that represents the usage that will be made.',
              },
              placeholders: {
                namePlaceholder: 'e.g.: Create ticket from Zabbix',
                userNamePlaceholder: 'e.g.: Zabbix',
              },
            },
            messages: {
              webhookActivated: 'Webhook \'{name}\' activated',
              webhookCreated: 'Webhook \'{name}\' created',
              webhookDeactivated: 'Webhook \'{name}\' deactivated',
              webhookDeleted: 'Webhook \'{name}\' deleted',
              webhookTokenCopiedToClipboard: 'Token copied to clipboard',
            },
          },
        },
        schedule: {
          form: {
            hints: {
              dayNotWorked: 'To indicate a day is not worked, just set both start and end to 0.',
              useH24: 'If enabled, public holidays will be considered as worked.',
              useCountry: 'Choose a country to base your schedule on. It will exclude public holidays from calculations.',
            },
          },
          messages: {
            scheduleUpdated: 'Schedule updated',
          },
        },
      },
      users: {
        common: {
          deactivateUserTitle: 'Deactivate {fullName} ({username}) ?',
          deactivateUserText: 'Are you sure you want to deactivate {fullName} ({username}) ?',
          deleteUserAlertText: 'By deleting an user, you may loose links and history records. Try deactivating instead. | By deleting an user, you may loose links and history records. Try deactivating instead. | By deleting users, you may loose links and history records. Try deactivating instead.',
          deleteUserCheckText: 'I prefer deleting this user | I prefer deleting this user | I prefer deleting these users',
          deleteUserTitle: 'Delete {fullName} ({username}) ?',
          deleteUserWarningText: 'Are you sure you want to delete {fullName} ({username}) ?',
          deactivateUsersText: 'Are you sure you want to deactivate selected user ? | Are you sure you want to deactivate selected user ? | Are you sure you want to deactivate selected users ?',
          deactivateUsersTitle: 'Deactivate selected user ? | Deactivate selecter user ? | Deactivate selected users ?',
          deleteUsersTitle: 'Delete selected user ? | Delete selected user ? | Delete selected users ?',
          deleteUsersWarningText: 'Are you sure you want to delete this user ? | Are you sure you want to delete this user ? | Are you sure you want to delete these users ?',
        },
        form: {
          formTab: {
            hints: {
              userContactSync: 'Changes on some fields will be synchronized with linked contact.',
              userCreation: 'A contact will be created and linked to the User. If desired contact already exist, create User from it instead.',
              userCreationActive: 'The user will be created as active.',
              userCreationInactive: 'The user will be created as inactive.',
              userTypePortal: 'This type of user will only have access to the portal, if enabled.',
              userTypePortalRef: 'This type of user will only have access to the portal, if enabled, but with company wide view.',
              userTypeSystem: 'This type of user will have access to the default platform.',
            },
          },
          groupsTab: {
            addGroupButton: 'Add this group | Add this group | Add these groups',
            searchGroups: 'Search group...',
            selectGroupsToAdd: 'Select groups to add...',
          },
          historyTab: {
            neverActivated: 'Never been activated.',
            neverDeactivated: 'Never been deactivated.',
            neverLoggedIn: 'Never logged in.',
          },
          permissionsTab: {
            infoText: 'This panel is only a summary of {fullName}\'s permissions and is readonly. Permissions are managed by group.',
          },
        },
        messages: {
          groupsAddedToUser: '{count} group added to user \'{username}\' | {count} group added to user \'{username}\' | {count} groups added to user \'{username}\'',
          groupRemovedFromUser: 'Group \'{groupName}\' removed from user \'{username}\'',
          userActivated: 'User \'{username}\' activated',
          userAvatarUpdated: '{fullName}\'s avatar updated',
          userCreated: 'User \'{username}\' created',
          userDeactivated: 'User \'{username}\' deactivated',
          userDeleted: 'User {fullName} ({username}) deleted',
          userPasswordUpdated: '{fullName}\'s password updated',
          userProfilePasswordUpdated: 'Password updated',
          userProfileUpdated: 'Profile updated',
          userUpdated: 'User \'{username}\' updated',
          usersActivated: '{effectiveCount} of {count} selected user activated | {effectiveCount} of {count} selected user activated | {effectiveCount} of {count} selected users activated',
          usersDeactivated: '{effectiveCount} of {count} selected user deactivated | {effectiveCount} of {count} selected user deactivated | {effectiveCount} of {count} selected users deactivated',
          usersDeleted: 'Selected user has been deleted | Selected user has been deleted | The {count} selected users have been deleted',
        },
      },
    },
    contact: {
      address: {
        common: {
          deleteAddressTitle: 'Delete address \'{name}\' ?',
          deleteAddressWarningText: 'Are you sure you want to delete address \'{name}\' ?',
        },
        messages: {
          addressCreated: 'Address created',
          addressDeleted: 'Address deleted',
          addressUpdated: 'Address updated',
        },
      },
      buttons: {
        archiveContact: 'Archive Contact',
        unarchiveContact: 'Unarchive Contact',
      },
      companies: {
        common: {
          deleteCompanyTitle: 'Delete company \'{name}\' ?',
          deleteCompanyWarningText: 'Are you sure you want to delete company \'{name}\' ?',
          deleteCompaniesTitle: 'Delete selected company ? | Delete selected company ? | Delete selected companies ?',
          deleteCompaniesWarningText: 'Are you sure you want to delete this company ? | Are you sure you want to delete this company ? | Are you sure you want to delete these companies ?',
        },
        messages: {
          companiesDeleted: 'Selected company has been deleted | Selected company has been deleted | The {count} selected companies have been deleted',
          companyCreated: 'Company \'{name}\' created',
          companyDeleted: 'Company \'{name}\' deleted',
          companyUpdated: 'Company updated',
        },
      },
      contacts: {
        common: {
          deleteContactAlertText: 'By deleting a contact, you may loose links and history records. Try archiving instead.',
          deleteContactCheckText: 'I prefer deleting this contact',
          deleteContactTitle: 'Delete {fullName} ?',
          deleteContactWarningText: 'Are you sure you want to delete {fullName} ?',
          setContactCompanyAdviceText: 'Records related to this contact will also be linked to this company.',
          setContactCompanyAlextText: 'Keep in mind that this change is irreversible, for data consistency.',
          setContactCompanyCheckText: 'I understood',
          setContactCompanyTitle: 'Warning: setting a company',
          setContactCompanyWarningText: 'You are trying to set a company ({companyName}) for this contact.',
        },
        form: {
          hints: {
            contactUserSync: 'Changes on some fields will be synchronized with linked user.'
          },
        },
        messages: {
          contactArchived: 'Contact \'{fullName}\' archived',
          contactCreated: 'Contact \'{fullName}\' created',
          contactDeleted: 'Contact \'{fullName}\' deleted',
          contactUnarchived: 'Contact \'{fullName}\' unarchived',
          contactUpdated: 'Contact updated',
          contactUserCreated: 'User created for contact \'{fullName}\'',
        },
      },
      tags: {
        common: {
          deleteTagTitle: 'Delete tag \'{name}\' ?',
          deleteTagWarningText: 'Are you sure you want to delete tag \'{name}\' ?',
          deleteTagsTitle: 'Delete selected tag ? | Delete selected tag ? | Delete selected tags ?',
          deleteTagsWarningText: 'Are you sure you want to delete this tag ? | Are you sure you want to delete this tag ? | Are you sure you want to delete these tags ?',
        },
        messages: {
          tagCreated: 'Tag \'{tagName}\' created',
          tagDeleted: 'Tag \'{tagName}\' deleted',
          tagUpdated: 'Tag updated',
          tagsDeleted: 'Selected tag has been deleted | Selected tag has been deleted | The {count} selected tags have been deleted',
        },
      },
      userFromContactFormDialog: {
        userTypeFormHint: {
          system: 'This type of user will have access to the default platform.',
          portal: 'This type of user will only have access to the portal, if enabled.',
          portalRef: 'This type of user will only have access to the portal, if enabled, but with company wide view.',
        },
      },
    },
    helpdesk: {
      ticketFeedbackForm: {
        checkingToken: 'Checking token',
        improve: 'It will help us improve our services.',
        invalidToken: 'Token is invalid or has already been used',
        letUsComment: 'Let us a comment',
        pleaseReview: 'Please let us a review for the ticket',
        thankYou: 'Thank you for your feedback !',
      },
      tickets: {
        messages: {
          ticketCreated: 'Ticket created',
          ticketUpdated: 'Ticket updated',
        },
      },
    },
    inventory: {

    },
    timesheets: {
      items: {
        common: {
          deleteTimesheetTitle: 'Delete timesheet ?',
          deleteTimesheetWarningText: 'Are you sure you want to delete this timesheet ?',
          deleteTimesheetsTitle: 'Delete selected timesheet ? | Delete selected timesheet ? | Delete selected timesheets ?',
          deleteTimesheetsWarningText: 'Are you sure you want to delete this timesheet ? | Are you sure you want to delete this timesheet ? | Are you sure you want to delete these timesheets ?',
        },
        messages: {
          timesheetDeleted: 'Timesheet \'{description}\' deleted',
          timesheetsDeleted: 'Selected timesheet has been deleted | Selected timesheet has been deleted | The {count} selected timesheets have been deleted',
        },
      },
    },
  },
  common: {
    buttons: {
      activate: 'Activate',
      add: 'Add',
      addAddress: 'Add address',
      archive: 'Archive',
      back: 'Back',
      cancel: 'Cancel',
      changePassword: 'Change password',
      copyToClipboard: 'Copy',
      create: 'Create',
      createAPIKey: 'Create API key',
      createTicket: 'Create ticket',
      createUser: 'Create user',
      createUserAPI: 'Create API user',
      createWebhook: 'Create webhook',
      deactivate: 'Deactivate',
      delete: 'Delete',
      edit: 'Edit',
      export: 'Export',
      home: 'Homepage',
      iAgree: 'I agree',
      learnMore: 'Learn more',
      loadMore: 'Load more',
      open: 'Open',
      remove: 'Remove',
      reset: 'Reset',
      rotate: 'Rotate',
      save: 'Save',
      selectAll: 'Select all',
      selectFiles: 'Select files',
      selectFilters: 'Select filters',
      submit: 'Submit',
      testConnection: 'Test Connection',
      unarchive: 'Unarchive',
      unselectAll: 'Unselect all',
      updateTicket: 'Update ticket',
      yes: 'Yes',
    },
    errors: {
      dateFromBeforeTo: 'Start date must be sooner than end date',
      dateToAfterFrom: 'End date must be later than start date',
      invalidDomainOneOf: 'One of the domains is invalid',
      invalidEmail: 'Invalid email address',
      invalidHostname: 'Invalid hostname',
      invalidPortAbove: 'The lowest allowed port is 0',
      invalidPortBelow: 'The highest allowed port is 65535',
      invalidPostalCode: 'Invalid postal code',
      invalidSSLAndTLSExclusive: 'SSL and TLS are mutually exclusive',
      passwordsDoNotMatch: 'Passwords do not match',
      required: 'Required',
    },
    hints: {
      pressToValidate: 'Press <enter>, <tab>, <space> or <,> to validate new entry',
    },
    labels: {
      actions: 'Action | Action | Actions',
      activated: 'Activated',
      address: 'Address',
      addresses: 'Addresses',
      apikeys: 'API Key | API Key | API Keys',
      applications: 'Applications',
      archived: 'Archived',
      assignee: 'Assignee',
      attempts: 'Attempt | Attempt | Attempts',
      author: 'Author',
      branding: 'Branding',
      categories: 'Category | Category | Categories',
      city: 'City',
      closed: 'Closed',
      closingDate: 'Closing Date',
      colors: 'Color | Color | Colors',
      companies: 'Company | Company | Companies',
      companyAddresses: 'Company Address | Company Address | Company Addresses',
      configTemplate: 'Configuration Template',
      configuration: 'Configuration',
      contacts: 'Contact | Contact | Contacts',
      contentHTML: 'HTML Content',
      contentText: 'Text Content',
      country: 'Country',
      createdBy: 'Created By',
      creationDate: 'Creation Date',
      customer: 'Customer',
      day0End: 'Monday (End)',
      day0Start: 'Monday (Start)',
      day1End: 'Tuesday (End)',
      day1Start: 'Tuesday (Start)',
      day2End: 'Wednesday (End)',
      day2Start: 'Wednesday (Start)',
      day3End: 'Thursday (End)',
      day3Start: 'Thursday (Start)',
      day4End: 'Friday (End)',
      day4Start: 'Friday (Start)',
      day5End: 'Saturday (End)',
      day5Start: 'Saturday (Start)',
      day6End: 'Sunday (End)',
      day6Start: 'Sunday (Start)',
      deactivated: 'Deactivated',
      defaultEmailFrom: 'Default Email Address (From)',
      description: 'Description',
      domain: 'Domaine',
      domains: 'Domains',
      duration: 'Duration',
      emailConfigClassicInbound: 'Inbound (IMAP/POP)',
      emailConfigClassicOutbound: 'Outbount (SMTP)',
      emailConfigTemplateClassic: 'Classic (SMTP-IMAP/POP)',
      emailConfigTemplateOffice: 'Office 365',
      emailPro: 'Professional Email',
      emails: 'Email | Email | Emails',
      feedbacks: 'Feedback | Feedback | Feedbacks',
      firstName: 'First Name',
      from: 'From',
      fullName: 'Full Name',
      globalSchedule: 'Schedule',
      groups: 'Group | Group | Groups',
      helpdeskTeams: 'Helpdesk Team | Helpdesk Team | Helpdesk Teams',
      hosts: 'Host | Host | Hosts',
      integrations: 'Integration | Integration | Integrations',
      language: 'Language',
      lastActivation: 'Last Activation',
      lastAttempt: 'Last Attempt',
      lastDeactivation: 'Last Deactivation',
      lastLogin: 'Last Login',
      lastName: 'Last Name',
      lastUpdate: 'Last Update',
      lastUsed: 'Last Used',
      logs: 'Logs',
      mailboxes: 'Mailbox | Mailbox | Mailboxes',
      name: 'Name',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      notes: 'Note | Note | Notes',
      opened: 'Open',
      outboundFailedEmails: 'Outbound Failed Email | Outbound Failed Email | Outbound Failed Emails',
      parameters: 'Parameter | Parameter | Parameters',
      partNumbers: 'Part Number | Part Number | Part Numbers',
      password: 'Password',
      permissions: 'Permission | Permission | Permissions',
      personal: 'Personal',
      phone: 'Phone',
      phonePerso: 'Personal Phone',
      phonePersoMobile: 'Personal Phone (mobile)',
      phonePro: 'Professional Phone',
      phoneProMobile: 'Professional Phone (mobile)',
      ports: 'Port | Port | Ports',
      priority: 'Priority',
      priorityHigh: 'High',
      priorityLow: 'Low',
      priorityNormal: 'Normal',
      professional: 'Professional',
      reason: 'Reason',
      replyTo: 'Reply To',
      selection: 'Selection',
      serialNumbers: 'Serial Number | Serial Number | Serial Numbers',
      serverType: 'Server Type',
      slaLevel: 'SLA Level',
      source: 'Source',
      status: 'Status',
      streetPerso: 'Address',
      subject: 'Subject',
      tags: 'Tag | Tag | Tags',
      teams: 'Team | Team | Teams',
      title: 'Title',
      to: 'To',
      token: 'Token',
      unarchived: 'Not Archived',
      unassigned: 'Unassigned',
      url: 'URL',
      username: 'Username',
      users: 'User | User | Users',
      usersAPI: 'API User | API User | API Users',
      usersPortal: 'Portal User | Portal User | Portal Users',
      usersPortalRef: 'Portal Ref. User | Portal Ref. User | Portal Ref. Users',
      usersSystem: 'System User | System User | System Users',
      userType: 'User Type',
      useSSL: 'Use SSL',
      useTLS: 'Use TLS',
      webhooks: 'Webhook | Webhook | Webhooks',
      workH24: 'Work 24/7',
      zipCodePerso: 'Zip/Postal Code',
    },
  },
  globalPages: {
    home: {
      askAdminText: 'Ask your administrator to get some access.',
      noAccessText: 'You don\'t have any access to the applications yet.',
    },
    notFound: {
      nothingHere: 'It seems there is nothing here',
      pageNotFound: 'PAGE NOT FOUND',
    },
  },
  timing: {
    years: 'year | year | years',
    months: 'month | month | months',
    weeks: 'week | week | weeks',
    days: 'day | day | days',
    hours: 'hour | hour | hours',
    minutes: 'minute | minute | minutes',
    seconds: 'second | second | seconds',
  },
}
