<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="
        tag
        && !editing
        && (
          hasPermission('add_contactcontacttag')
          || hasPermission('change_contactcontacttag')
          || hasPermission('delete_contactcontacttag')
        )">
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_contactcontacttag')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_contactcontacttag')"
          text
          @click="deleteTag(tag)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          {{ $t('common.buttons.delete') }}
        </v-btn>
        <v-btn
          v-if="hasPermission('add_contactcontacttag')"
          text
          :to="{ name: 'ContactContactTagFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ $t('common.buttons.create') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="tag"/>
    <template v-if="!loadingTag">
      <v-form
        v-model="isFormValid"
        class="pa-4"
        :disabled="!editing">
        <v-row class="my-0">
          <v-col
            cols="12"
            lg="9"
            md="6">
            <v-text-field
              v-model="form.name"
              :label="$t('common.labels.name')"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6">
            <v-text-field
              v-model="form.color"
              :label="$tc('common.labels.colors', 1)">
              <template v-slot:append>
                <v-menu
                  offsetY
                  left
                  :close-on-content-click="false"
                  :disabled="!editing">
                  <template v-slot:activator="{ on }">
                    <div :style="colorMenuStyle" v-on="on"/>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="form.color"
                        flat
                        hide-mode-switch/>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="cancel()">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!isFormValid"
            v-if="hasPermission('change_contactcontacttag') || hasPermission('add_contactcontacttag')"
            @click="!tag ? createTag() : updateTag(tag)">
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import ContactContactTagService from '@/services/contact/contact.contact.tag.service.js'
export default {
  name: 'ContactContactTagForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    tagId: {
      type: [Number, String],
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingTag: false,
      tag: null,
      form: {
        name: '',
        color: 'BEBEBEFF',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    if (this.tagId) await this.getTag(this.tagId)
    if (this.tag) this.initFormFields()
  },
  computed: {
    colorMenuStyle () {
      const color = this.form.color
      return {
        backgroundColor: color,
        border: '1px solid black',
        cursor: 'pointer',
        height: '25px',
        width: '25px',
      }
    }
  },
  methods: {
    async getTag (tagId) {
      this.loadingTag = true
      this.tag = await ContactContactTagService.getTag(tagId)
      this.loadingTag = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.tag[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.tag) {
        for (let field in this.form) {
          this.form[field] = this.tag[field]
        }
      } else {
        history.back()
      }
    },
    async createTag () {
      const { name, color } = this.$data.form
      const createdTag = await ContactContactTagService.createTag({
        name: name,
        color: color,
      })
      if (createdTag) {
        this.cancelEdit()
        this.$router.push({ name: 'ContactContactTagFormRead', params: { tagId: createdTag.id } })
        this.$dialog.notify.success(
          this.$t('applications.contact.tags.messages.tagCreated', { tagName: createdTag.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateTag (tag) {
      const { name, color } = this.$data.form
      const updatedTag = await ContactContactTagService.updateTag(tag.id, {
        name: name,
        color: color,
      })
      if (updatedTag) {
        this.tag = updatedTag
        this.$dialog.notify.success(
          this.$t('applications.contact.tags.messages.tagUpdated'), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.cancelEdit()
      }
    },
    async deleteTag (tag) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.contact.tags.common.deleteTagTitle', { name: tag.name }),
        warningText: this.$t('applications.contact.tags.common.deleteTagWarningText', { name: tag.name }),
        recordName: this.$tc('common.labels.tags', 1),
        recordNamePlural: this.$tc('common.labels.tags', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactContactTagService.deleteTags({ ids: [tag.id] })
          if (response) {
            this.$router.push({ name: 'ContactContactTagListing' })
            this.$dialog.notify.success(
              this.$t('applications.contact.tags.messages.tagDeleted', { tagName: tag.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
