import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskTicketCategoryService {
  static getCategory = async (id) => {
    try {
      const response = await http.get(`helpdesk/categories/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCategories = async (urlSearchParams) => {
    try {
      const response = await http.get('helpdesk/categories', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createCategory = async (data) => {
    try {
      const response = await http.post('helpdesk/categories/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateCategory = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/categories/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteCategory = async (categoryId) => {
    try {
      const response = await http.delete(`helpdesk/categories/${categoryId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteCategories = async (data) => {
    try {
      const response = await http.post('helpdesk/categories/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCategoriesInput = async () => {
    try {
      const response = await http.get('helpdesk/categories/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
