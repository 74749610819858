var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"my-0"},[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"3"}},[_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end"},[_c('span',{staticClass:"caption my-auto"},[_vm._v(" "+_vm._s(_vm.$t('showing'))+" "),_c('span',{staticStyle:{"color":"#1E88E5"}},[_vm._v(_vm._s(_vm.users.length))]),_vm._v(" "+_vm._s(_vm.$t('of'))+" "+_vm._s(_vm.pagination?.total_records)+" ")]),(_vm.pagination?.has_next_page)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),(_vm.pagination?.has_next_page)?_c('v-btn',{attrs:{"color":"blue darken-1","outlined":"","loading":_vm.loadingMore},on:{"click":function($event){return _vm.$emit('loadNextUserPage')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.loadMore'))+" ")],1):_vm._e()],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","multi-sort":"","checkbox-color":"blue darken-2","loading":_vm.loading,"show-select":_vm.hasPermission('change_user')
          || _vm.hasPermission('delete_user'),"headers":_vm.tableHeaders,"items":_vm.users,"hide-default-footer":_vm.users.length < 10},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"my-12"},[_vm._v(_vm._s(_vm.$t('loading'))+"...")])]},proxy:true},{key:"no-data",fn:function(){return [_c('h-no-records-found',{attrs:{"createFormViewName":"UserFormCreate"}})]},proxy:true},{key:`item.username`,fn:function({ item }){return [_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"24px"}},[_c('v-img',{attrs:{"src":item.picture ? _vm.getPictureURL(item.picture) : require('@/assets/images/no-user-picture.jpeg')}})],1),_vm._v(" "+_vm._s(item.username)+" ")]}},{key:`item.language`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getFlag(item.language))+" ")]}},{key:`item.is_active`,fn:function({ item }){return [(item.is_active)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-thick")])]}},{key:`item.user_type`,fn:function({ item }){return [_c('v-chip',{staticClass:"font-weight-bold white--text",attrs:{"x-small":"","label":"","color":item.user_type === 'system' ? 'green' : 'primary'}},[_vm._v(" "+_vm._s(item.user_type_display)+" ")])]}},{key:`item.date_joined`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date_joined,"calendar"))+" ")]}},{key:`item.last_login`,fn:function({ item }){return [(item.last_login)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_login,"calendar"))+" ")]:_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(_vm.hasPermission('view_user'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"to":{ name: 'UserFormRead', params: { userId: item.id } },"icon":""}},on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.open'))+" ")]):_vm._e(),(
              _vm.hasPermission('change_user')
              && ![1, 2, _vm.userMe.id].includes(item.id)
            )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){item.is_active ? _vm.$emit('deactivateUser', item) : _vm.$emit('activateUser', item)}}},on),[(item.is_active)?_c('v-icon',[_vm._v("mdi-archive-arrow-down")]):_c('v-icon',[_vm._v("mdi-archive-arrow-up")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t('common.buttons.deactivate') : _vm.$t('common.buttons.activate'))+" ")]):_vm._e(),(
              _vm.hasPermission('delete_user')
              && ![1, 2, _vm.userMe.id].includes(item.id)
            )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteUser', item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.delete'))+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }