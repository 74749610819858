<template>
	<v-main>
		<v-container fluid class="view-container">
			<v-toolbar tile elevation=1 class="mb-4">
				<v-row>
					<v-col cols=12 md=6 class="d-flex align-center">
						<v-btn color="blue darken-2" dark @click="createTicket">
							<v-icon left>mdi-plus</v-icon>
							{{ $t('common.buttons.createTicket') }}
						</v-btn>
					</v-col>
					<v-col cols=12 md=6>
						<h-filtering-bar
							:style="{'width': '100%'}"
							:availableFilters="availableFilters"/>
					</v-col>
				</v-row>
			</v-toolbar>
			<v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">
              {{ $t('showing') }} <span style="color:#1E88E5">{{ tickets.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
            </span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="loadNextTicketPage">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
				<v-data-table
					multi-sort
          :loading="loading"
					:headers="headers"
					:items="tickets"
          :items-per-page=10
          :hide-default-footer="tickets.length < 11"
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
            'show-first-last-page': true,
          }">
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip v-if="item.name.length > 47" right open-delay="150">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ item.name | truncate(47, '...') }}
                </span>
              </template>
              {{ item.name }}
            </v-tooltip>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <v-chip
              v-if="item.category"
              small
              label
              outlined
              :color="item.category.color">
              {{ item.category.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <v-tooltip right open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="priorityAttributes(item).color">mdi-{{ priorityAttributes(item).icon }}</v-icon>
              </template>
              <span>{{ priorityAttributes(item).text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.author.full_name`]="{ item }">
            <v-avatar size="24px" class="mr-1">
              <v-img :src="item.author.picture ? getPictureURL(item.author.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-avatar>
            <!-- TODO: Have to take care of display in case author is deleted -->
            {{ item.author.full_name }}
          </template>
          <template v-slot:[`item.contact.full_name`]="{ item }">
            <template v-if="item.contact">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="item.contact.picture ? getPictureURL(item.contact.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              <!-- TODO: Have to take care of display in case contact is deleted -->
              {{ item.contact.full_name }}
            </template>
          </template>
          <template v-slot:[`item.create_date`]="{ item }">
            {{ item.create_date | moment("calendar") }}
          </template>
          <template v-slot:[`item.update_date`]="{ item }">
            {{ item.update_date | moment("from") }}
          </template>
          <template v-slot:[`item.close_date`]="{ item }">
            <template v-if="item.close_date">
              {{ item.close_date | moment("calendar") }}
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="openUpdateTicketDialog(item.id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.edit') }}
            </v-tooltip>
          </template>
				</v-data-table>
			</v-card>
		</v-container>
    <helpdesk-ticket-update-form-portal-dialog
      v-if="showUpdateDialog"
      v-model="showUpdateDialog"
      :userMe="userMe"
      :ticketId="currentTicketId"
      @updateTicket="updateTicket"/>
	</v-main>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
import HelpdeskTicketPortalService from '@/services/helpdesk/helpdesk.ticket.portal.service'
import HelpdeskTicketCreateFormPortalDialog from '@/views/helpdesk/portal/HelpdeskTicketCreateFormPortalDialog'
import HelpdeskTicketUpdateFormPortalDialog from '@/views/helpdesk/portal/HelpdeskTicketUpdateFormPortalDialog'
export default {
	name: 'HelpdeskPortalTicketList',
	components: {
    HFilteringBar,
    HelpdeskTicketUpdateFormPortalDialog,
  },
	data () {
		return {
			loading: false,
			loadingMore: false,
      pagination: null,
			headers: [
				{ value: 'name', text: this.$t('common.labels.title') },
        { value: 'category', text: this.$tc('common.labels.categories', 1) },
        { value: 'priority', text: this.$t('common.labels.priority') },
        { value: 'team.name', text: this.$tc('common.labels.teams', 1)},
        { value: 'author.full_name', text: this.$t('common.labels.createdBy') },
        { value: 'contact.full_name', text: this.$tc('common.labels.contacts', 1) },
				{ value: 'create_date', text: this.$t('common.labels.creationDate') },
        { value: 'update_date', text: this.$t('common.labels.lastUpdate') },
        { value: 'close_date', text: this.$t('common.labels.closingDate') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
			],
			tickets: [],
			availableFilters: [
        { fieldName: 'name', label: this.$t('common.labels.name'), type: 'string' },
        { fieldName: 'contact', label: this.$tc('common.labels.contacts', 1), type: 'string' },
        { fieldName: 'category', label: this.$tc('common.labels.categories', 1), type: 'string' },
        { fieldName: 'priority', label: this.$t('common.labels.priority'), type: 'predefinedValue', value: 'low', valueDisplay: this.$t('common.labels.priorityLow') },
        { fieldName: 'priority', label: this.$t('common.labels.priority'), type: 'predefinedValue', value: 'normal', valueDisplay: this.$t('common.labels.priorityNormal') },
        { fieldName: 'priority', label: this.$t('common.labels.priority'), type: 'predefinedValue', value: 'high', valueDisplay: this.$t('common.labels.priorityHigh') },
        { fieldName: 'is_closed', label: this.$t('common.labels.closed'), optionText: this.$t('common.labels.closed'), type: 'boolean', value: true },
        { fieldName: 'is_closed', label: this.$t('common.labels.closed'), optionText: this.$t('common.labels.opened'), type: 'boolean', value: false },
      ],
      showUpdateDialog: false,
      currentTicketId: null,
		}
	},
	async mounted () {
		await this.getPortalTickets(this.$store.state.url.urlSearchParams)
	},
	watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getPortalTickets(this.$store.state.url.urlSearchParams)
    },
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
	methods: {
		async getPortalTickets (urlSearchParams) {
			this.loading = true
			const responseData = await HelpdeskTicketPortalService.getTickets(urlSearchParams)
			this.tickets = responseData.tickets
      this.pagination = responseData.pagination
			this.loading = false
		},
		async loadNextTicketPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await HelpdeskTicketPortalService.getTickets(urlSearchParams)
        this.pagination = responseData.pagination
        const newTicketPage = responseData.tickets
        const currentTickets = this.tickets
        const updatedTicketList = currentTickets.concat(newTicketPage)
        this.tickets = updatedTicketList
        this.loadingMore = false
      }
		},
    priorityAttributes (ticket) {
      if (ticket.priority === 'low') return { icon: 'chevron-up', color: 'green', text: this.$t('common.labels.priorityLow') }
      if (ticket.priority === 'normal') return { icon: 'chevron-double-up', color: 'orange', text: this.$t('common.labels.priorityNormal') }
      if (ticket.priority === 'high') return { icon: 'chevron-triple-up', color: 'red', text: this.$t('common.labels.priorityHigh') }
    },
    createTicket () {
      this.$dialog.show(HelpdeskTicketCreateFormPortalDialog, {
        userMe: this.userMe,
        persistent: true,
        waitForResult: true,
      }).then(async createdTicket => {
        if (createdTicket) {
          this.tickets.unshift(createdTicket)
          this.pagination.total_records += 1
          this.$dialog.notify.success(
            this.$t('applications.helpdesk.tickets.messages.ticketCreated'), {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      })
    },
    openUpdateTicketDialog (ticketId) {
      this.currentTicketId = ticketId
      this.showUpdateDialog  = true
    },
    updateTicket (updatedTicket) {
      const index = this.tickets.findIndex(ticket => ticket.id === updatedTicket.id)
      this.tickets.splice(index, 1, updatedTicket)
      this.$dialog.notify.success(
        this.$t('applications.helpdesk.tickets.messages.ticketUpdated'), {
          position: 'top-right',
          border: 'left',
          dense: true,
          timeout: 5000,
        }
      )
    },
	},
}
</script>

<style scoped>
.container.view-container {
	height: calc(100% - 48px);
	padding: 16px;
}
.v-toolbar {
	height: unset!important;
}
.v-toolbar::v-deep .v-toolbar__content {
	height: fit-content!important;
}
</style>
