<template>
  <v-dialog
    v-model="show"
    fullscreen
    transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar flat dark color="blue darken-1">
        <v-btn
          icon
          dark
          @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('common.buttons.updateTicket') }}: {{ ticket?.name }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-4">
        <v-col cols=12>
        <!-- <v-col cols=12 md=6> -->
          <v-card elevation="3">
            <v-form v-model="isFormValid" class="pa-4">
              <v-text-field
                v-model="form.name"
                required
                clearable
                autofocus
                type="text"
                maxlength="255"
                counter
                :label="$t('common.labels.title')"
                :rules="[formRules.required]"/>
              <h-rich-text-editor
                v-model="form.description"
                class="my-4"
                required
                editorBorders
                editorPadding
                opacifyDisabled
                formatParagraph
                formatBold
                formatItalic
                formatStrike
                formatMarker
                formatH1
                formatH2
                formatH3
                formatListB
                formatListN
                formatCode
                formatCodeBlock
                formatSeparator
                historyUndo
                historyRedo
                :editable="true"/>
              <v-autocomplete
                v-model="form.contact"
                return-object
                item-text="full_name"
                :items="contacts"
                :label="$tc('common.labels.contacts', 1)"
                :disabled="userMe?.user_type === 'portal'"
                :loading="loadingContactsInput"
                @click="getContactsInput">
              </v-autocomplete>
              <v-row class="my-0">
                <v-col cols=12 md=6>
                  <v-autocomplete
                    v-model="form.category"
                    clearable
                    return-object
                    item-text="name"
                    :items="categories"
                    :loading="loadingCategoriesInput"
                    :label="$tc('common.labels.categories', 1)"
                    @click="getCategoriesInput">
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        label
                        outlined
                        class="font-weight-bold"
                        :color="data.item.color">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip
                        small
                        label
                        outlined
                        class="font-weight-bold"
                        :color="data.item.color">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols=12 md=6>
                  <v-select
                    v-model="form.priority"
                    item-value="value"
                    :items="priorities"
                    :label="$t('common.labels.priority')"
                    :menu-props="{ top: false, offsetY: true }">
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <v-icon :color="item.iconColor">mdi-{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          {{ item.text }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <v-icon class="mr-2" :color="data.item.iconColor">mdi-{{ data.item.icon }}</v-icon>{{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-divider/>
            <v-card-actions>
              <!-- <v-spacer/> -->
              <v-btn text @click="show = false">
                {{ $t('common.buttons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid || !form.description"
                @click="updateTicket()">
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col cols=12 md=6>
          <v-card elevation="3">logger will come here</v-card>
        </v-col> -->
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import ContactContactService from '@/services/contact/contact.contact.service'
import HelpdeskTicketCategoryService from '@/services/helpdesk/helpdesk.ticket.category.service'
import HelpdeskTicketPortalService from '@/services/helpdesk/helpdesk.ticket.portal.service'
export default {
  name: 'HelpdeskTicketUpdateFormPortalDialog',
  components: {
    HRichTextEditor,
  },
  props: {
    value: Boolean,
    userMe: {
      type: Object,
      required: true,
    },
    ticketId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      ticket: null,
      loadingCategoriesInput: false,
      loadingContactsInput: false,
      categories: [],
      contacts: [],
      priorities: [
        { text: this.$t('common.labels.priorityLow'), value: 'low', icon: 'chevron-up', iconColor: 'green' },
        { text: this.$t('common.labels.priorityNormal'), value: 'normal', icon: 'chevron-double-up', iconColor: 'orange' },
        { text: this.$t('common.labels.priorityHigh'), value: 'high', icon: 'chevron-triple-up', iconColor: 'red' },
      ],
      form: {
        name: '',
        description: '',
        contact: null,
        category: null,
        priority: 'normal',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    this.ticket = await HelpdeskTicketPortalService.getTicket(this.ticketId)
    this.initFormFields()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async getCategoriesInput () {
      this.loadingCategoriesInput = true
      this.categories = await HelpdeskTicketCategoryService.getCategoriesInput()
      this.loadingCategoriesInput = false
    },
    async getContactsInput () {
      if (this.userMe?.user_type === 'portal_referent') {
        this.loadingContactsInput = true
        this.contacts = await ContactContactService.getPortalContactsInput()
        this.loadingContactsInput = false
      }
    },
    initFormFields () {
      for (const field in this.form) {
        if (field === 'contact') this.contacts = [this.ticket[field]]
        if (field === 'category') this.categories = [this.ticket[field]]
        this.form[field] = this.ticket[field]
      }
    },
    async updateTicket () {
      const {
        name,
        description,
        category,
        priority,
        contact,
      } = this.$data.form
      const responseData = await HelpdeskTicketPortalService.updateTicket(this.ticketId, {
        name: name,
        description: description,
        priority: priority,
        category_id: category ? category.id : null,
        contact_id: contact ? contact.id : null,
      })
      if (responseData) {
        this.ticket = responseData
        this.$emit('updateTicket', this.ticket)
        this.show = false
      }
    },
  },
}
</script>
