<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="failedMails"
    :hide-default-footer="failedMails.length < 10">
    <template v-slot:loading>
      <div class="my-12">{{ $t('loading') }}...</div>
    </template>
    <template v-slot:[`item.create_date`]="{ item }">
      {{ item.create_date | moment("calendar") }}
    </template>
    <template v-slot:[`item.last_attempt_date`]="{ item }">
      {{ item.last_attempt_date | moment("calendar") }}
    </template>
    <template v-slot:[`item.headers`]="{ item }">
      <v-btn icon @click="viewContent(JSON.stringify(item.headers, null, 2),  'json')">
        <v-icon v-if="item.headers">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.text_content`]="{ item }">
      <v-btn icon @click="viewContent(item.text_content, '')">
        <v-icon v-if="item.text_content">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.html_content`]="{ item }">
      <v-btn icon @click="viewContent(item.html_content, 'html')">
        <v-icon v-if="item.html_content">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip v-if="hasPermission('delete_outboundfailedmail')" bottom open-delay="250">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="deleteFailedMail(item)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        {{ $t('common.buttons.delete') }}
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
import DeleteModal from '@/common/components/DeleteModal'
import HCodeViewerModal from '@/common/components/HCodeViewerModal'
export default {
  name: 'OutboundFailedMailList',
  data () {
    return {
      loading: false,
      failedMails: [],
      headers: [
        { value: 'subject', text: this.$t('common.labels.subject') },
        { value: 'from_address', text: this.$t('common.labels.from') },
        { value: 'to_addresses', text: this.$t('common.labels.to') },
        { value: 'cc_addresses', text: 'Cc' },
        { value: 'bcc_addresses', text: 'Bcc' },
        { value: 'reply_to_addresses', text: this.$t('common.labels.replyTo') },
        { value: 'failure_exc_msg', text: this.$t('common.labels.reason') },
        { value: 'create_date', text: this.$t('common.labels.creationDate') },
        { value: 'last_attempt_date', text: this.$t('common.labels.lastAttempt') },
        { value: 'try_count', text: `# ${this.$tc('common.labels.attempts', 2)}` },
        { value: 'headers', text: 'Headers' },
        { value: 'text_content', text: this.$t('common.labels.contentText') },
        { value: 'html_content', text: this.$t('common.labels.contentHTML') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ],
    }
  },
  async mounted () {
    await this.getOutboundFailedMails()
  },
  methods: {
    async getOutboundFailedMails () {
      this.loading = true
      this.failedMails = await BaseSettingsService.getOutboundFailedMails()
      this.loading = false
    },
    viewContent (content, language) {
      this.$dialog.show(HCodeViewerModal, {
        content: content,
        language: language,
        persistent: true,
        waitForResult: true,
      })
    },
    async deleteFailedMail (mail) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.settings.emails.outboundFailedEmails.deleteOutboundFailedEmailTitle'),
        warningText: this.$t('applications.base.settings.emails.outboundFailedEmails.deleteOutboundFailedEmailWarningText'),
        alertMessage: this.$t('applications.base.settings.emails.outboundFailedEmails.deleteOutboundFailedEmailAlertText'),
        checkRequired: true,
        checkMessage: this.$t('common.buttons.iAgree'),
        recordName: this.$tc('common.labels.outboundFailedEmails', 1),
        recordNamePlural: this.$tc('common.labels.outboundFailedEmails', 2),
        persistent: true,
        waitForResult: true
      }).then(async yes => {
        if (yes) {
          const response = await BaseSettingsService.deleteOutboundFailedMailManually(mail.id)
          if (response) {
            this.failedMails.splice(this.failedMails.indexOf(mail), 1)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.emails.outboundFailedEmails.messages.failedEmailDeleted'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
