<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">Showing <span style="color:#1E88E5">{{ tickets.length }}</span> of {{ pagination?.total_records }}</span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="loadNextTicketPage">
              <v-icon left>mdi-download</v-icon>
              Load more
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          dense
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_helpdeskticket')"
          :headers="headers"
          :items="tickets"
          :items-per-page=15
          :hide-default-footer="tickets.length < 16"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 30],
            'show-first-last-page': true,
          }"
          v-model="selectedTickets">
          <template v-slot:loading>
            <div class="my-12">Loading...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="HelpdeskTicketFormCreate"/>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip v-if="item.name.length > 47" right open-delay="150">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ item.name | truncate(47, '...') }}
                </span>
              </template>
              {{ item.name }}
            </v-tooltip>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <template v-if="item.contact">{{ item.contact.full_identification }}</template>
            <template v-else-if="item.company">{{ item.company.name }}</template>
          </template>
          <template v-slot:[`item.create_date`]="{ item }">
            {{ item.create_date | moment("calendar") }}
          </template>
          <template v-slot:[`item.update_date`]="{ item }">
            {{ item.update_date | moment("from") }}
          </template>
          <template v-slot:[`item.close_date`]="{ item }">
            <template v-if="item.close_date">
              {{ item.close_date | moment("calendar") }}
            </template>
          </template>
          <template v-slot:[`item.time_to_close`]="{ item }">
            <template v-if="item.time_to_close">
              {{ item.time_to_close | duration("humanize") }}
            </template>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <v-chip
              v-if="item.category"
              small
              label
              outlined
              :color="item.category.color">
              {{ item.category.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <v-tooltip right open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="priorityAttributes(item).color">mdi-{{ priorityAttributes(item).icon }}</v-icon>
              </template>
              <span>{{ priorityAttributes(item).text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip v-if="isTimesheetsAppActivated" right open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  dense
                  class="mr-1"
                  :color="item.timesheets_count > 0 ? 'blue darken-1' : '#E0E0E0'">
                  mdi-clipboard-text-clock
                </v-icon>
              </template>
              {{ item.timesheets_count > 1 ? `${item.timesheets_count} Timesheets` : `${item.timesheets_count} Timesheet` }}
            </v-tooltip>
            <v-tooltip left open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  dense
                  class="mr-1"
                  :color="subtasksAttributes(item).color">
                  mdi-{{ subtasksAttributes(item).icon }}
                </v-icon>
              </template>
              <span v-if="item.subtasks_count > 0">{{ item.subtasks_done_count }} of {{ item.subtasks_count }} {{ subtasksIconText(item) }}</span>
              <span v-else>0 Subtask</span>
            </v-tooltip>
            <v-tooltip left open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  dense
                  class="mr-1"
                  :color="item.has_answer ? 'green' : '#E0E0E0'">
                  mdi-comment-check
                </v-icon>
              </template>
              {{ item.has_answer ? 'Answered' : 'Unanswered' }}
            </v-tooltip>
            <v-tooltip right open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  dense
                  class="mr-1"
                  :color="slaStatusAttributes(item).color">
                  mdi-{{ slaStatusAttributes(item).icon }}
                </v-icon>
              </template>
              {{ slaStatusAttributes(item).text }}
            </v-tooltip>
            <v-tooltip right open-delay="200">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  dense
                  :color="item.is_closed ? 'green' : '#E0E0E0'">
                  <template v-if="item.is_closed">mdi-check-circle</template>
                  <template v-else>mdi-checkbox-blank-circle-outline</template>
                </v-icon>
              </template>
              {{ item.is_closed ? 'Closed' : 'Open' }}
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_helpdeskticket')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'HelpdeskTicketFormRead', params: { helpdeskTicketId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              Open
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_helpdeskticket')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="deleteTickets([item])" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { eventBus } from '../../../main.js'
import DeleteModal from '@/common/components/DeleteModal'
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
export default {
  name: 'HelpeskTicketList',
  components: { HNoRecordsFound },
  data () {
    return {
      loading: false,
      loadingMore: false,
      pagination: null,
      tickets: [],
      selectedTickets: [],
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'team_stage_display', text: 'Team / Stage' },
        { value: 'author.full_name', text: this.$t('common.labels.createdBy') },
        { value: 'assigned_user.full_name', text: 'Assigned to' },
        { value: 'company', text: 'Company / Contact' },
        { value: 'create_date', text: 'Creation Date' },
        { value: 'update_date', text: 'Last Modification' },
        { value: 'category', text: this.$tc('common.labels.categories', 1) },
        { value: 'priority', text: 'Priority' },
        { value: 'status', text: 'Status', sortable: false },
        { value: 'close_date', text: 'Closing Date' },
        { value: 'time_to_close', text: 'Time to Close' },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ],
    }
  },
  computed: {
    isTimesheetsAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === "timesheets") !== undefined
    },
  },
  mounted () {
    this.getTickets(this.$store.state.url.urlSearchParams)
    eventBus.$on('delete-selected-helpdesk-tickets', () => {
      this.deleteTickets(this.selectedTickets)
    })
  },
  beforeDestroy () {
    this.$store.commit('SET_SELECTED_HELPDESK_TICKETS', [])
  },
  destroyed () {
    // It should be removed automatically but it doesn't so for now, remove it manually
    // TODO: Ensure it is done automatically
    eventBus.$off('delete-selected-helpdesk-tickets')
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getTickets(this.$store.state.url.urlSearchParams)
      this.selectedTickets = []
    },
    selectedTickets (newSelection, oldSelection) {
      this.$store.commit('SET_SELECTED_HELPDESK_TICKETS', newSelection)
    },
  },
  methods: {
    allTicketSubtasksDone (ticket) {
      return ticket.subtasks_done_count === ticket.subtasks_count
    },
    subtasksIconText (ticket) {
      if (ticket.subtasks_count > 1) {
        return 'Subtasks done'
      } else {
        return 'Subtask done'
      }
    },
    subtasksAttributes (ticket) {
      if (ticket.subtasks_count === 0) {
        return { icon: 'check-circle', color: '#E0E0E0' }
      } else if (!this.allTicketSubtasksDone(ticket)) {
        return { icon: 'progress-wrench', color: 'orange' }
      } else {
        return { icon: 'check-circle', color: 'green' }
      }
    },
    slaStatusAttributes (ticket) {
      if (ticket.sla_status === 'ok') return { icon: 'clock-check', color: 'green', text: 'SLA Ok' }
      if (ticket.sla_status === 'warning') return { icon: 'clock-alert', color: 'amber lighten-1', text: 'SLA Warning' }
      if (ticket.sla_status === 'alert') return { icon: 'clock-alert', color: 'orange darken-1', text: 'SLA Alert' }
      if (ticket.sla_status === 'failed') return { icon: 'clock-remove', color: 'red', text: 'SLA Failed' }
    },
    priorityAttributes (ticket) {
      if (ticket.priority === 'low') return { icon: 'chevron-up', color: 'green', text: 'Low' }
      if (ticket.priority === 'normal') return { icon: 'chevron-double-up', color: 'orange', text: 'Normal' }
      if (ticket.priority === 'high') return { icon: 'chevron-triple-up', color: 'red', text: 'High' }
    },
    async getTickets (urlSearchParams) {
      this.loading = true
      const responseData = await HelpdeskTicketService.getTickets(urlSearchParams)
      this.tickets = responseData.tickets
      this.pagination = responseData.pagination
      this.loading = false
    },
    async loadNextTicketPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await HelpdeskTicketService.getTickets(urlSearchParams)
        this.pagination = responseData.pagination
        const newTicketPage = responseData.tickets
        const currentTickets = this.tickets
        const updatedTicketList = currentTickets.concat(newTicketPage)
        this.tickets = updatedTicketList
        this.loadingMore = false
      }
    },
    deleteTickets (ticketsToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Ticket',
        recordNamePlural: 'Tickets',
        summaryHeaders: [
          { value: 'name', text: 'Name' },
          { value: 'team.name', text: 'Team' },
          { value: 'assigned_user.full_name', text: 'Assigned to' },
          { value: 'is_closed', text: 'Status' },
        ],
        summaryRecords: ticketsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const ticketIdsToDelete = ticketsToDelete.map(ticket => ticket.id)
          const response = await HelpdeskTicketService.deleteTickets({ ids: ticketIdsToDelete })
          if (response) {
            this.tickets = this.tickets.filter(undeletedTicket => !ticketIdsToDelete.includes(undeletedTicket.id))
            this.selectedTickets = []
            this.pagination.total_records -= ticketIdsToDelete.length
            this.$dialog.notify.success(
              `${ticketsToDelete.length} ${ticketsToDelete.length > 1 ? 'tickets' : 'ticket'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
