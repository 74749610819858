import HelpdeskMainMenu from '@/views/helpdesk/menus/HelpdeskMainMenu'
import HelpdeskTicketFeedbackForm from '@/views/helpdesk/ticket/HelpdeskTicketFeedbackForm'
import dashboardRoutes from './models/dashboard.routes'
import settingsRoutes from './models/settings.routes'
import teamRoutes from './models/team.routes'
import ticketRoutes from './models/ticket.routes'
import ticketTagRoutes from './models/ticket-tag.routes'
import ticketCategoryRoutes from './models/ticket-category.routes'

export default [
  {
    path: '/helpdesk',
    name: 'HelpdeskMainMenu',
    component: HelpdeskMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...teamRoutes,
      ...ticketRoutes,
      ...dashboardRoutes,
      ...ticketTagRoutes,
      ...ticketCategoryRoutes,
      ...settingsRoutes,
    ],
  },
  {
    path: '/helpdesk/tickets/:helpdeskTicketId/feedback',
    name: 'HelpdeskTicketFeedbackForm',
    component: HelpdeskTicketFeedbackForm,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: false,
    },
  },
]
