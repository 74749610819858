<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">
              {{ $t('showing') }} <span style="color:#1E88E5">{{ users.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
            </span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextUserPage')">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          v-model="selectedUsers"
          dense
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="
            hasPermission('change_user')
            || hasPermission('delete_user')
          "
          :headers="tableHeaders"
          :items="users"
          :hide-default-footer="users.length < 10">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="UserFormCreate"/>
          </template>
          <template v-slot:[`item.username`]="{ item }">
            <v-avatar size="24px" class="mr-1">
              <v-img :src="item.picture ? getPictureURL(item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-avatar>
            {{ item.username }}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{ getFlag(item.language) }}
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
            <v-icon v-else color="red">mdi-close-thick</v-icon>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <v-chip
              x-small
              label
              :color="item.user_type === 'system' ? 'green' : 'primary'"
              class="font-weight-bold white--text">
              {{ item.user_type_display }}
            </v-chip>
          </template>
          <template v-slot:[`item.date_joined`]="{ item }">
            {{ item.date_joined | moment("calendar") }}
          </template>
          <template v-slot:[`item.last_login`]="{ item }">
            <template v-if="item.last_login">
              {{ item.last_login | moment("calendar") }}
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_user')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'UserFormRead', params: { userId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.open') }}
            </v-tooltip>
            <v-tooltip
              v-if="
                hasPermission('change_user')
                && ![1, 2, userMe.id].includes(item.id)
              "
              bottom
              open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="item.is_active ? $emit('deactivateUser', item) : $emit('activateUser', item)">
                  <v-icon v-if="item.is_active">mdi-archive-arrow-down</v-icon>
                  <v-icon v-else>mdi-archive-arrow-up</v-icon>
                </v-btn>
              </template>
              {{ item.is_active ? $t('common.buttons.deactivate') : $t('common.buttons.activate') }}
            </v-tooltip>
            <v-tooltip
              v-if="
                hasPermission('delete_user')
                && ![1, 2, userMe.id].includes(item.id)
              "
              bottom
              open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteUser', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'UserList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
    selectedUsers: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    tableHeaders () {
      return [
        { value: 'username', text: this.$t('common.labels.username') },
        { value: 'full_name', text: this.$t('common.labels.name') },
        { value: 'email', text: this.$tc('common.labels.emails', 1) },
        { value: 'language', text: this.$t('common.labels.language') },
        { value: 'user_type', text: this.$t('common.labels.userType') },
        { value: 'is_active', text: this.$t('common.labels.activated') },
        { value: 'date_joined', text: this.$t('common.labels.creationDate') },
        { value: 'last_login', text: this.$t('common.labels.lastLogin') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
  },
  beforeDestroy () {
    this.selectedUsers = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedUsers = []
    },
  },
  methods: {
    getFlag (language) {
      const flags = {
        en: '🇬🇧',
        fr: '🇫🇷',
      }
      return flags[language] || 'no-flag-found'
    },
  },
}
</script>
