<template>
  <div class="ma-12">
    <v-progress-circular
      :size="60"
      :width="6"
      color="light-blue darken-4"
      indeterminate/>
    <div class="mt-4 caption">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HRequestLoading',
  props: {
    text: {
      type: String,
      default: function () {
        return `${this.$t('loading')}...`
      },
    },
  },
}
</script>
