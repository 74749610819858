<template>
  <div v-if="!loadingSettings">
    <v-toolbar
      v-if="settings && hasPermission('change_helpdesksettings')"
      flat
      dense>
      <v-toolbar-items>
        <v-btn
          v-if="!formIsEditing && hasPermission('change_helpdesksettings')"
          text
          @click="edit()">
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="formIsEditing && hasPermission('change_helpdesksettings')"
          text
          color="green"
          @click="updateSettings()">
          Save
        </v-btn>
        <v-btn
          v-if="formIsEditing"
          text
          @click="cancel()">
          Cancel
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-form
      class="pa-4"
      v-model="isFormValid"
      :disabled="!formIsEditing">
      <!-- <v-subheader>
        <h3>General</h3>
      </v-subheader>
      <v-divider/> -->
      <v-subheader>
        <h3>Emailing</h3>
      </v-subheader>
      <v-divider/>
      <v-switch
        v-model="form.notify_assignee_when_add_attachment"
        label="Notify assignee when adding attachment"
        inset
        dense
        color="green"
        hint="Will send an email to the ticket assignee when an attachment is added to a ticket."
        persistent-hint/>
      <v-switch
        v-model="form.skip_unknown_domains_on_inbound_mail"
        label="Skip inbound mails coming from unknown domains"
        inset
        dense
        color="green"
        hint="This will prevent mails coming from unknown domains to be processed. This will take over auto-creation of companies."
        persistent-hint/>
      <v-switch
        v-model="form.auto_create_company_on_inbound_mail"
        label="Auto create company on inbound mail"
        inset
        dense
        color="green"
        hint="Will automatically create a company based on the sender's email address of an inbound mail."
        persistent-hint/>
      <v-subheader class="mt-5">
        <h3>Portal</h3>
      </v-subheader>
      <v-divider/>
      <v-switch
        v-model="form.portal_enabled"
        label="Enable portal users to log into the portal."
        inset
        dense
        color="green"
        hint="They will only have access to a portal with their own stuff (Helpdesk Tickets, Inventory Items,...)"
        persistent-hint/>
      <v-subheader class="mt-5">
        <h3>Timesheets</h3>
      </v-subheader>
      <v-divider/>
      <v-alert
        v-if="!isTimesheetsAppActivated"
        type="info"
        text
        outlined
        dense
        class="mt-4">
        Timesheets application must be activated to use these settings.
      </v-alert>
      <v-switch
        v-model="form.use_timer_on_ticket_form"
        label="Use timer on ticket form"
        inset
        dense
        hint="This will display a timer on ticket forms to see how much time has passed on it."
        persistent-hint
        :color="isTimesheetsAppActivated ? 'green' : 'grey'"
        :disabled="!isTimesheetsAppActivated || !formIsEditing"
        @change="changeTimerActivation()"/>
      <v-switch
        v-model="form.auto_start_timer_on_ticket_form"
        label="Auto start the timer when opening the ticket form"
        inset
        dense
        :color="isTimesheetsAppActivated ? 'green' : 'grey'"
        :disabled="!isTimesheetsAppActivated || !formIsEditing || !form.use_timer_on_ticket_form"
        @change="changeTimerAutoActivation()"/>
      <v-switch
        v-model="form.auto_start_timer_assignee_only"
        label="Auto start the timer when opening the ticket form, only if being its assignee."
        inset
        dense
        :color="isTimesheetsAppActivated ? 'green' : 'grey'"
        :disabled="!isTimesheetsAppActivated || !formIsEditing || !form.auto_start_timer_on_ticket_form"/>
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import HelpdeskSettingsService from '@/services/helpdesk/helpdesk.settings.service';
export default {
  name: 'HelpdeskGeneralSettingsForm',
  components: {
    HRequestLoading,
  },
  data () {
    return {
      formIsEditing: false,
      isFormValid: false,
      loadingSettings: false,
      settings: null,
      form: {
        notify_assignee_when_add_attachment: true,
        skip_unknown_domains_on_inbound_mail: false,
        auto_create_company_on_inbound_mail: false,
        portal_enabled: false,
        use_timer_on_ticket_form: false,
        auto_start_timer_on_ticket_form: false,
        auto_start_timer_assignee_only: false,
      },
    }
  },
  computed: {
    isTimesheetsAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === "timesheets") !== undefined
    },
  },
  async mounted () {
    await this.getSettings()
    if (this.settings) {
      this.initFormFields()
    }
  },
  methods: {
    async getSettings () {
      this.loadingSettings = true
      this.settings = await HelpdeskSettingsService.getSettings()
      this.loadingSettings = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.settings[field]
      }
    },
    edit () {
      this.formIsEditing = true
    },
    cancelEdit () {
      this.formIsEditing = false
    },
    cancel () {
      if (this.settings) {
        this.initFormFields()
      }
      this.cancelEdit()
    },
    changeTimerActivation () {
      if (!this.form.use_timer_on_ticket_form) {
        this.form.auto_start_timer_on_ticket_form = false
        this.form.auto_start_timer_assignee_only = false
      }
    },
    changeTimerAutoActivation () {
      if (!this.form.auto_start_timer_on_ticket_form) {
        this.form.auto_start_timer_assignee_only = false
      }
    },
    async updateSettings () {
      const {
        notify_assignee_when_add_attachment,
        skip_unknown_domains_on_inbound_mail,
        auto_create_company_on_inbound_mail,
        portal_enabled,
        use_timer_on_ticket_form,
        auto_start_timer_on_ticket_form,
        auto_start_timer_assignee_only,
      } = this.$data.form
      const updatedSettings = await HelpdeskSettingsService.updateSettings({
        notify_assignee_when_add_attachment: notify_assignee_when_add_attachment,
        skip_unknown_domains_on_inbound_mail: skip_unknown_domains_on_inbound_mail,
        auto_create_company_on_inbound_mail: auto_create_company_on_inbound_mail,
        portal_enabled: portal_enabled,
        use_timer_on_ticket_form: use_timer_on_ticket_form,
        auto_start_timer_on_ticket_form: auto_start_timer_on_ticket_form,
        auto_start_timer_assignee_only: auto_start_timer_assignee_only,
      })
      if (updatedSettings) {
        this.cancelEdit()
        this.settings = updatedSettings
        await this.$store.dispatch('FETCH_ALL_SETTINGS_BY_APPLICATION')
        this.$dialog.notify.success(
          'Helpdesk Settings updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
  },
}
</script>
