import HelpdeskTicketCategoryListing from '@/views/helpdesk/category/HelpdeskTicketCategoryListing'
import HelpdeskTicketCategoryFormCreate from '@/views/helpdesk/category/HelpdeskTicketCategoryFormCreate'
import HelpdeskTicketCategoryFormRead from '@/views/helpdesk/category/HelpdeskTicketCategoryFormRead'

export default [
  {
    path: 'categories',
    name: 'HelpdeskTicketCategoryListing',
    component: HelpdeskTicketCategoryListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_helpdeskticketcategory',
    },
  },
  {
    path: 'categories/create',
    name: 'HelpdeskTicketCategoryFormCreate',
    component: HelpdeskTicketCategoryFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_helpdeskticketcategory',
    },
  },
  {
    path: 'categories/:helpdeskTicketCategoryId',
    name: 'HelpdeskTicketCategoryFormRead',
    component: HelpdeskTicketCategoryFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_helpdeskticketcategory',
    },
  },
]
