<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="team && !editing">
      <v-toolbar-items>
        <v-btn
          text
          v-if="hasPermission('change_helpdeskteam')"
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          text
          v-if="hasPermission('delete_helpdeskteam')"
          @click="deleteTeam(team)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          text
          v-if="hasPermission('add_helpdeskteam')"
          :to="{ name: 'HelpdeskTeamFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          :class="{ 'disable-events': !hasPermission('view_helpdeskticket') }"
          :to="{
            name: 'HelpdeskTicketKanban',
            params: { helpdeskTeamId: team.id }
          }">
          {{ team.open_tickets_count }} Tickets
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="team"/>
    <v-tabs vertical background-color="grey lighten-4">
      <v-tab>
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-form-select</v-icon>
          </template>
          Team
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!team">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-account-plus</v-icon>
          </template>
          Users
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!team">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-cog</v-icon>
          </template>
          Configuration
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <template v-if="!loadingTeam">
          <v-form
            class="pa-4"
            v-model="isFormValid"
            :disabled="!editing">
            <v-text-field
              v-model="form.name"
              label="Name"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="editing"
              :rules="[formRules.required]"/>
            <v-textarea
              v-model="form.description"
              label="Description"
              clearable
              type="text"
              rows="1"
              auto-grow
              maxlength="255"
              :counter="editing"/>
            <v-select
              v-model="form.auto_assign_rule"
              label="Automatic Assign Rule"
              clearable
              item-value="value"
              :menu-props="{ top: false, offsetY: true }"
              :items="autoAssignRules"/>
            <v-text-field
              v-model="form.inbound_mail"
              label="Inbound Mail"
              clearable
              type="email"
              maxlength="254"
              hint="Will try to create tickets with incoming mails from that mailbox. A corresponding Inbound Mailbox must be set."
              persistent-hint
              class="mb-4"
              :counter="editing"
              :rules="[formRules.email]"/>
            <v-divider class="my-2"/>
            <v-switch
              v-model="form.ask_feedback_to_contact_on_close"
              label="Ask feedback to contact on ticket closure"
              inset
              dense
              color="green"
              hint="Will send an email to the ticket contact to ask feedback when closing the ticket"
              persistent-hint/>
          </v-form>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn text @click="cancel()">
                Cancel
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid"
                v-if="hasPermission('change_helpdeskteam') || hasPermission('add_helpdeskteam')"
                @click="!team ? createTeam() : updateTeam(team)">
                Save
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-tab-item>
      <v-tab-item v-if="team" class="pa-4">
        <v-row class="my-0">
          <v-col class="pb-0">
            <v-autocomplete
              v-if="hasPermission('change_helpdeskteam')"
              v-model="selectedUsers"
              label="Select users to add..."
              multiple
              hide-selected
              clearable
              :loading="loadingUsersInput"
              :items="membersTeamHasNot"
              item-text="full_name"
              return-object
              @click="getUsersInput">
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.full_name"/>
                  <v-list-item-subtitle v-html="data.item.username"/>
                </v-list-item-content>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  small
                  pill
                  close
                  class="font-weight-bold"
                  @click:close="removeUserFromSelection(data.item)">
                  <v-avatar left>
                    <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                  </v-avatar>
                  {{ data.item.full_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pb-0 d-flex align-center">
            <v-btn
              text
              color="green"
              v-if="selectedUsers.length && hasPermission('change_helpdeskteam')"
              @click="addUsersInTeam(team, selectedUsers)">
              <v-icon small class="mr-1">mdi-plus</v-icon>
              {{ selectedUsers.length > 1 ? 'Add these users' : 'Add this user' }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title>
            Members
            <v-spacer/>
            <v-text-field
              v-model="teamMemberSearch"
              append-icon="mdi-magnify"
              label="Search member..."
              single-line
              hide-details/>
          </v-card-title>
          <v-data-table
            :loading="loadingTeamMembers"
            :headers="teamMembersHeaders"
            :items="teamMembers"
            :search="teamMemberSearch">
            <template v-slot:loading>
              <div class="my-12">Loading...</div>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
              <v-icon v-else color="red">mdi-close-thick</v-icon>
            </template>
            <template v-slot:[`item.last_login`]="{ item }">
              <template v-if="item.last_login">
                {{ item.last_login | moment("calendar") }}
              </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip
                v-if="hasPermission('change_helpdeskteam')"
                bottom
                open-delay="250">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="removeUserFromTeam(team, item)" icon>
                    <v-icon color="red">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                Remove
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-form
          class="pa-4"
          v-model="isFormValid"
          :disabled="!editing">
          <v-row class="my-0">
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model.number="form.open_tickets_warning_threshold"
              type="number"
              label="Open Tickets Warning"
              :hint="openTicketsWarningHint"
              persistent-hint
              :rules="[
                formRules.positiveNumber,
                formRules.openTicketsWarningLTEAlert
              ]"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model.number="form.open_tickets_alert_threshold"
              type="number"
              label="Open Tickets Alert"
              :hint="openTicketsAlertHint"
              persistent-hint
              :rules="[
                formRules.positiveNumber,
                formRules.openTicketsAlertGTEWarning
              ]"/>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="my-0">
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model.number="form.unassigned_tickets_warning_threshold"
              type="number"
              label="Unassigned Tickets Warning"
              :hint="unassignedTicketsWarningHint"
              persistent-hint
              :rules="[
                formRules.positiveNumber,
                formRules.unassignedTicketsWarningLTEAlert
              ]"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model.number="form.unassigned_tickets_alert_threshold"
              type="number"
              label="Unassigned Tickets Alert"
              :hint="unassignedTicketsAlertHint"
              persistent-hint
              :rules="[
                formRules.positiveNumber,
                formRules.unassignedTicketsAlertGTEWarning
              ]"/>
            </v-col>
          </v-row>
        </v-form>
        <template v-if="editing">
          <v-divider/>
          <v-card-actions>
            <v-btn text @click="cancel()">
              Cancel
            </v-btn>
            <v-btn
              text
              color="green"
              :disabled="!isFormValid"
              v-if="hasPermission('change_helpdeskteam') || hasPermission('add_helpdeskteam')"
              @click="!team ? createTeam() : updateTeam(team)">
              Save
            </v-btn>
          </v-card-actions>
        </template>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import BaseUserService from '@/services/base/base.user.service'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
export default {
  name: 'HelpdeskTeamForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    helpdeskTeamId: {
      type: [Number, String],
      default: () => { return null }
    },
  },
  data () {
    return {
      teamMemberSearch: '',
      loadingTeamMembers: false,
      loadingUsersInput: false,
      loadingTeam: false,
      team: null,
      teamMembers: [],
      users: [],
      form: {
        name: '',
        description: '',
        inbound_mail: null,
        auto_assign_rule: null,
        ask_feedback_to_contact_on_close: false,
        open_tickets_warning_threshold: 0,
        open_tickets_alert_threshold: 0,
        unassigned_tickets_warning_threshold: 0,
        unassigned_tickets_alert_threshold: 0,
      },
      isFormValid: false,
      autoAssignRules: [
        { value: 'least_first', text: 'Least First' },
      ],
      teamMembersHeaders: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'username', text: 'Username' },
        { value: 'email', text: 'Email' },
        { value: 'is_active', text: 'Active' },
        { value: 'last_login', text: 'Last Login' },
        { value: 'actions', text: 'Actions', sortable: false },
      ],
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        positiveNumber: value => value >= 0 || 'Must be >= 0.',
        openTicketsWarningLTEAlert: value => value <= this.form.open_tickets_alert_threshold || 'Must be <= than the Alert threshold.',
        openTicketsAlertGTEWarning: value => value >= this.form.open_tickets_warning_threshold || 'Must be >= than the Warning threshold.',
        unassignedTicketsWarningLTEAlert: value => value <= this.form.unassigned_tickets_alert_threshold || 'Must be <= than the Alert threshold.',
        unassignedTicketsAlertGTEWarning: value => value >= this.form.unassigned_tickets_warning_threshold || 'Must be >= than the Warning threshold.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('common.errors.invalidEmail')
        },
      },
      selectedUsers: [],
    }
  },
  async mounted () {
    if (this.helpdeskTeamId) {
      await this.getTeam(this.helpdeskTeamId)
      if (this.team) {
        this.initFormFields()
        this.getTeamMembers(this.helpdeskTeamId)
      }
    }
  },
  computed: {
    openTicketsWarningHint: function () {
      if (this.form.open_tickets_warning_threshold <= 0) {
        return "There won't be any Warning status."
      } else {
        return `The Warning status will be shown when there are more than ${this.form.open_tickets_warning_threshold} opened Tickets.`
      }
    },
    openTicketsAlertHint: function () {
      if (this.form.open_tickets_alert_threshold <= 0) {
        return "There won't be any Alert status."
      } else {
        return `The Alert status will be shown when there are more than ${this.form.open_tickets_alert_threshold} opened Tickets.`
      }
    },
    unassignedTicketsWarningHint: function () {
      if (this.form.unassigned_tickets_warning_threshold <= 0) {
        return "There won't be any Warning status."
      } else {
        return `The Warning status will be shown when there are more than ${this.form.unassigned_tickets_warning_threshold} unassigned Tickets.`
      }
    },
    unassignedTicketsAlertHint: function () {
      if (this.form.unassigned_tickets_alert_threshold <= 0) {
        return "There won't be any Alert status."
      } else {
        return `The Alert status will be shown when there are more than ${this.form.unassigned_tickets_alert_threshold} unassigned Tickets.`
      }
    },
    membersTeamHasNot () {
      const teamMembersIds = this.teamMembers.map(member => member.id)
      return this.users.filter(user => !teamMembersIds.includes(user.id))
    },
  },
  methods: {
    async getTeam (teamId) {
      this.loadingTeam = true
      this.team = await HelpdeskTeamService.getTeam(teamId)
      this.loadingTeam = false
    },
    async getTeamMembers (teamId) {
      this.loadingTeamMembers = true
      this.teamMembers = await HelpdeskTeamService.getTeamMembers(teamId)
      this.loadingTeamMembers = false
    },
    async getUsersInput () {
      this.loadingUsersInput = true
      this.users = await BaseUserService.getUsersInput()
      this.loadingUsersInput = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.team[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.team) {
        for (let field in this.form) {
          this.form[field] = this.team[field]
        }
      } else {
        history.back()
      }
    },
    async createTeam () {
      const {
        name,
        inbound_mail,
        description,
        auto_assign_rule,
        ask_feedback_to_contact_on_close,
      } = this.$data.form
      const createdTeam = await HelpdeskTeamService.createTeam({
        name: name,
        description: description,
        auto_assign_rule: auto_assign_rule ? auto_assign_rule : null,
        inbound_mail: inbound_mail ? inbound_mail : null,
        ask_feedback_to_contact_on_close: ask_feedback_to_contact_on_close,
      })
      if (createdTeam) {
        this.cancelEdit()
        this.$dialog.notify.success(
          `Team '${createdTeam.name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.$router.push({
          name: 'HelpdeskTeamFormRead',
          params: { helpdeskTeamId: createdTeam.id }
        })
      }
    },
    async updateTeam (team) {
      const {
        name,
        description,
        auto_assign_rule,
        inbound_mail,
        ask_feedback_to_contact_on_close,
        open_tickets_warning_threshold,
        open_tickets_alert_threshold,
        unassigned_tickets_warning_threshold,
        unassigned_tickets_alert_threshold,
      } = this.$data.form
      const updatedTeam = await HelpdeskTeamService.updateTeam(team.id, {
        name: name,
        description: description,
        auto_assign_rule: auto_assign_rule ? auto_assign_rule : null,
        inbound_mail: inbound_mail ? inbound_mail : null,
        ask_feedback_to_contact_on_close: ask_feedback_to_contact_on_close,
        open_tickets_warning_threshold: open_tickets_warning_threshold,
        open_tickets_alert_threshold: open_tickets_alert_threshold,
        unassigned_tickets_warning_threshold: unassigned_tickets_warning_threshold,
        unassigned_tickets_alert_threshold: unassigned_tickets_alert_threshold,
      })
      if (updatedTeam) {
        this.team = updatedTeam
        this.cancelEdit()
        this.$dialog.notify.success(
          'Team updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteTeam (team) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Team',
        recordNamePlural: 'Teams',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTeamService.deleteTeams({ ids: [team.id] })
          if (response) {
            this.$dialog.notify.success(
              `Team '${team.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'HelpdeskTeamListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async addUsersInTeam (team, selectedUsers) {
      const userIds = selectedUsers.map(user => user.id)
      const teamMembers = await HelpdeskTeamService.addMembersToTeam(team.id, {
        user_ids: userIds
      })
      if (teamMembers) {
        this.teamMembers = teamMembers
        this.selectedUsers = []
        this.$dialog.notify.success(
          `${userIds.length} ${userIds.length > 1 ? 'users ' : 'user '} added to team '${team.name}'`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async removeUserFromTeam (team, user) {
      const teamMembers = await HelpdeskTeamService.removeMemberFromTeam(team.id, {
        user_id: user.id
      })
      if (teamMembers) {
        this.teamMembers = teamMembers
        this.$dialog.notify.success(
          `User '${user.full_name}' removed from team '${team.name}'`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    removeUserFromSelection (user) {
      this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id)
    },
  },
}
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
</style>
