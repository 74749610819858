<template>
  <div>
    <helpdesk-ticket-category-listing-toolbar
      :viewType="viewType"
      :selectedCategories="selectedCategories"
      @setListView="setListView"
      @deleteCategories="deleteCategories"/>
    <transition name="router-anim" mode="out-in">
      <helpdesk-ticket-category-list
        v-if="viewType === 'list'"
        v-model="selectedCategories"
        :categories="categories"
        :loading="loading"
        @deleteCategory="deleteCategory"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketCategoryList from '@/views/helpdesk/category/HelpdeskTicketCategoryList'
import HelpdeskTicketCategoryListingToolbar from '@/views/helpdesk/category/HelpdeskTicketCategoryListingToolbar'
import HelpdeskTicketCategoryService from '@/services/helpdesk/helpdesk.ticket.category.service'
export default {
  name: 'HelpdeskTicketCategoryListing',
  components: {
    HelpdeskTicketCategoryList,
    HelpdeskTicketCategoryListingToolbar,
  },
  data () {
    return {
      loading: false,
      viewType: 'list',
      categories: [],
      selectedCategories: [],
    }
  },
  mounted () {
    this.getCategories(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getCategories(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    async getCategories (urlSearchParams) {
      this.loading = true
      this.categories = await HelpdeskTicketCategoryService.getCategories(urlSearchParams)
      this.loading = false
    },
    deleteCategory (category) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Category',
        recordNamePlural: 'Categories',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketCategoryService.deleteCategory(category.id)
          if (response) {
            this.categories.splice(this.categories.indexOf(category), 1)
            this.selectedCategories = []
            this.$dialog.notify.success(
              `Category '${category.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteCategories (categoriesToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Category',
        recordNamePlural: 'Categories',
        summaryHeaders: [
          { value: 'name', text: this.$t('common.labels.name') },
          { value: 'tickets_count', text: '# Tickets' },
        ],
        summaryRecords: categoriesToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const categoryIdsToDelete = categoriesToDelete.map(category => category.id)
          const response = await HelpdeskTicketCategoryService.deleteCategories({ ids: categoryIdsToDelete })
          if (response) {
            this.categories = this.categories.filter(undeletedCategory => !categoryIdsToDelete.includes(undeletedCategory.id))
            this.selectedCategories = []
            this.$dialog.notify.success(
              `${categoriesToDelete.length} ${categoriesToDelete.length > 1 ? 'categories' : 'category'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
