<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-data-table
          v-model="selectedCategories"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_helpdeskticketcategory')"
          :headers="headers"
          :items="categories"
          :hide-default-footer="categories.length < 10">
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="HelpdeskTicketCategoryFormCreate"/>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-chip
              small
              label
              outlined
              class="font-weight-bold"
              :color="item.color">
              {{ item.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_helpdeskticketcategory')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'HelpdeskTicketCategoryFormRead', params: { helpdeskTicketCategoryId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              Open
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_helpdeskticketcategory')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteCategory', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'HelpdeskTicketCategoryList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      headers: [
        { value: 'name', text: 'Label' },
        { value: 'tickets_count', text: '# Tickets' },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ],
    }
  },
  computed: {
    selectedCategories: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    }
  },
  beforeDestroy () {
    this.selectedCategories = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedCategories = []
    },
  },
}
</script>
