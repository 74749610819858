<template>
  <div v-if="!loading">
    <v-toolbar v-if="hasPermission('change_brandingsettings')" flat dense>
      <v-toolbar-items>
        <v-btn
          v-if="!formIsEditing && hasPermission('change_brandingsettings')"
          text
          @click="edit()">
          <v-icon small left>mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          v-if="formIsEditing"
          text
          @click="cancel()">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-divider vertical v-if="formIsEditing"/>
        <v-btn
          v-if="formIsEditing && hasPermission('change_brandingsettings')"
          text
          color="green"
          @click="updateBrandingSettings()">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-form v-model="isFormValid" class="pa-4" :disabled="!formIsEditing">
      <v-row>
        <v-col cols=12 md=6>
          <v-text-field
            v-model="form.name"
            :label="$t('common.labels.name')"
            required
            autofocus
            type="text"
            maxlength="50"
            :counter="formIsEditing"
            :rules="[formRules.required]"/>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols=12 lg=3 md=6>
          <v-text-field v-model="form.color" maxlength="9" :label="$tc('common.labels.colors', 1)" :counter="formIsEditing">
            <template v-slot:prepend>
              <v-menu
                offsetY
                left
                :disabled="!formIsEditing"
                :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <div v-on="on" :style="colorMenuStyle"></div>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="form.color"
                      flat
                      hide-mode-switch/>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'BrandingSettingsForm',
  mixins: [ DialogUnsavedChanges ],
  components: { HRequestLoading },
  data () {
    return {
      loading: false,
      brandingSettings: null,
      formIsEditing: false,
      isFormValid: false,
      form: {
        name: '',
        color: '',
      },
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    await this.getBrandingSettings()
    if (this.brandingSettings) {
      this.initFormFields()
    }
  },
  computed: {
    colorMenuStyle () {
      const color = this.form.color
      return {
        backgroundColor: color,
        border: '1px solid black',
        cursor: 'pointer',
        height: '25px',
        width: '25px',
      }
    },
  },
  methods: {
    async getBrandingSettings () {
      this.loading = true
      this.brandingSettings = await BaseSettingsService.getBrandingSettings()
      this.loading = false
    },
    edit () {
      this.formIsEditing = true
    },
    cancelEdit () {
      this.formIsEditing = false
    },
    cancel () {
      if (this.brandingSettings) {
        this.initFormFields()
      }
      this.cancelEdit()
    },
    initFormFields () {
      for (const field in this.form) {
        this.form[field] = this.brandingSettings[field]
      }
    },
    async updateBrandingSettings () {
      const { name, color } = this.$data.form
      const updatedBranding = await BaseSettingsService.updateBrandingSettings({
        name: name,
        color: color,
      })
      if (updatedBranding) {
        this.cancelEdit()
        this.brandingSettings = updatedBranding
      }
    }
  },
}
</script>
